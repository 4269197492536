import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TabMenuModule } from 'primeng/tabmenu';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {TooltipModule} from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NewCustomerComponent } from './components/new-customer/new-customer.component';
import { ShipToChangeComponent } from './components/ship-to-change/ship-to-change.component';
import { CustomerRenewalComponent } from './components/customer-renewal/customer-renewal.component';
import { MaterialAdditionComponent } from './components/material-addition/material-addition.component';
import { HomeComponent } from './components/home/home.component';
import { ShippingRequirementsComponent } from './components/new-customer/shipping-requirements/shipping-requirements.component';
import { BulkTruckRequestComponent } from './components/new-customer/shipping-requirements/bulk-truck-request/bulk-truck-request.component';
import { NewCustomerSetupComponent } from './components/new-customer/new-customer-setup/new-customer-setup.component';
import { CreditApplicationComponent } from './components/new-customer/credit-application/credit-application.component';
import { AdminComponent } from './components/admin/admin.component';
import { NewCustomerFopComponent } from './components/admin/new-customer-fop/new-customer-fop.component';
import { PackageTruckRequestComponent } from './components/new-customer/shipping-requirements/package-truck-request/package-truck-request.component';
import { RailRequestComponent } from './components/new-customer/shipping-requirements/rail-request/rail-request.component';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';
import { NewCustomerGridComponent } from './components/admin/new-customer-grid/new-customer-grid.component';
import { HistoryAndCommentsComponent } from './components/new-customer/history-and-comments/history-and-comments.component';
import { UserModuleComponent } from './components/admin/user-module/user-module.component';
import { UserRegistrationComponent } from './components/admin/user-module/user-registration/user-registration.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ConfirmationService } from 'primeng/api';
import { TaxExemptCertificateComponent } from './components/ship-to-change/tax-exempt-certificate/tax-exempt-certificate.component';
import { TestLoginComponent } from './components/admin/test-login/test-login.component';
import { MasterDataComponent } from './components/admin/master-data-management/master-data-management.component';
import { CountryManagementComponent } from './components/admin/country-module/country-management.component';
import { CustomerAccountSpecialistComponent } from './components/admin/customer-account-specialist/customer-account-specialist.component';
import { LoginComponent } from './components/login/login.component';
import { SamlComponent } from './components/saml/saml.component';
import { BuModuleComponent } from './components/admin/bu-module/bu-module.component';
import { SbuModuleComponent } from './components/admin/sbu-module/sbu-module.component';
import { EsignComponent } from './components/shared/esign/esign.component';
import { ContractManagementModuleComponent } from './components/admin/contract-management-module/contract-management-module.component';
import { SafePipeModule } from 'safe-pipe';
import { BasfSignatoryDetailsComponent } from './components/admin/contract-management-module/basf-signatory-details/basf-signatory-details.component';
import { CustomerContractDocumentComponent } from './components/new-customer/customer-contract-document/customer-contract-document.component';
import { DocumentationComponent } from './components/admin/documentation/documentation.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NewCustomerComponent,
    ShipToChangeComponent,
    CustomerRenewalComponent,
    MaterialAdditionComponent,
    HomeComponent,
    ShippingRequirementsComponent,
    BulkTruckRequestComponent,
    NewCustomerSetupComponent,
    CreditApplicationComponent,
    AdminComponent,
    NewCustomerFopComponent,
    PackageTruckRequestComponent,
    RailRequestComponent,
    NewCustomerGridComponent,
    HistoryAndCommentsComponent,
    UserModuleComponent,
    UserRegistrationComponent,
    CommentsComponent,
    TaxExemptCertificateComponent,
    TestLoginComponent,
    MasterDataComponent,
    CountryManagementComponent,
    CustomerAccountSpecialistComponent,
    LoginComponent,
    SamlComponent,
    BuModuleComponent,
    SbuModuleComponent,
    EsignComponent,
    ContractManagementModuleComponent,
    BasfSignatoryDetailsComponent,
    CustomerContractDocumentComponent,
    DocumentationComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    ToastrModule,
    
    NgxSpinnerModule,
    TabMenuModule,
    TabViewModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    CheckboxModule,
    InputTextModule,
    RadioButtonModule,
    ButtonModule,
    DialogModule,
    TableModule,
    MultiSelectModule,
    InputTextareaModule,
    TooltipModule,
    FileUploadModule,
    PdfViewerModule,
    SafePipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [NgxSpinnerModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

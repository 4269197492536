import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserRegistrationComponent implements OnInit {
  @Input() formData: any;
  @Input() formType: any;
  @Output() reLoadGrid = new EventEmitter();
  userId = parseInt(sessionStorage.getItem('userId') + '');
  apiResp: any;
  distributionChannelDropDown: any = [];
  salesOrgDropDown: any = [];
  apiRequestObj: any = {
    user_id: 0,
    basf_user_id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    createdBy: '',
    distribution_Channel: 0,
    sales_Org: 0,
    business_unit: [],
    regionId: 0,
    is_active: true,
    is_admin: true,
    countries: [],
  };
  selectedBU: any[] =[];
  selectedCountries: any[]=[];
  regionDropDown = [
    {
      id: 1,
      regionName: 'NA',
    },
  ];
  countries = [
    {
      country_id: 1,
      country_name: 'United states',
      country_Code: 'US',
      modifedBy: 0,
    },
    {
      country_id: 2,
      country_name: 'Canada',
      country_Code: 'CA',
      modifedBy: 0,
    },
  ];
  businessUnits = [];
  countryDropDown: any;

  constructor(
    private _service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getAllDivisions();
    this.getDistributionChannelDropDown();
    this.getSalesOrgDropDown();
    this.getCountryDropDown();

    if (this.formType !== 'New') {
      this.selectedBU = [];
      this.selectedCountries = [];

      this.selectedCountries = [];
      this.formData.countries?.split(',').forEach((cu: any) => {
        this.countries.forEach((cData: any) => {
          if (cu == cData.country_Code) {
            this.selectedCountries.push(cData.country_id);
          }
        });
      });
    }
  }

  getAllDivisions() {
    this._service.getAllDivisions().subscribe((response: any) => {
      if (!response['isError']) {
        this.businessUnits = response.response;

        let tBUnits = this.formData['businessUnits'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.businessUnits?.find((bu) => bu['divisionName'] == t);
          if (fBUnit) {
            this.selectedBU.push(fBUnit['divisionId']);
          }
        });
      }
    });
  }

  getCountryDropDown() {
    this._service.getAllCountries().subscribe((response: any) => {
      if (!response['isError']) {
        this.countryDropDown = response.response;
      } else {
        this._service.displayToastr(response);
      }
    });
  }

  getDistributionChannelDropDown() {
    this._service
      .getGridAllMasterData('DISTRIBUTION CHANNEL')
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.distributionChannelDropDown = response.response;
        } else {
          this._service.displayToastr(response);
        }
      });
  }

  getSalesOrgDropDown() {
    this._service
      .getGridAllMasterData('SalesOrg')
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.salesOrgDropDown = response.response;
        } else {
          this._service.displayToastr(response);
        }
      });
  }

  submitUser() {
    this.spinner.show();
    if (this.formType === 'New') {
      this.apiRequestObj['user_id'] = 0;
      this.apiRequestObj['createdBy'] = this.userId.toString();
      this.apiRequestObj['createdDate'] = new Date();
    } else {
      this.apiRequestObj['user_id'] = this.formData.user_id;
    }
    this.apiRequestObj['basf_user_id'] = this.formData.basf_user_id;
    this.apiRequestObj['firstName'] = this.formData.firstName;
    this.apiRequestObj['lastName'] = this.formData.lastName;
    this.apiRequestObj['emailAddress'] = this.formData.emailAddress;
    this.apiRequestObj['is_admin'] = this.formData.is_admin;
    this.apiRequestObj['distribution_Channel'] =
      this.formData.distribution_Channel;
    this.apiRequestObj['sales_Org'] = this.formData.sales_Org;
    this.apiRequestObj['regionId'] = this.formData.regionId;
    let businessUnits: any[] = [];
    this.selectedBU.forEach((sbu: any) => {
      let bObj = {
        id: 0,
        userId: this.apiRequestObj['user_id'],
        businessUnit_Id: sbu,
      };
      businessUnits.push(bObj);
    });
    this.apiRequestObj['business_unit'] = businessUnits;
    let countriesData: any[] = [];
    this.selectedCountries?.forEach((country: any) => {
      let bObj = {
        id: 0,
        userId: this.apiRequestObj['user_id'],
        countryId: country,
      };
      countriesData.push(bObj);
    });
    this.apiRequestObj['countries'] = countriesData;
    this._service
      .postCreateorUpdateUser(this.apiRequestObj)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
        if (!response['isError']) {
          this._service.displayToastr(response);
          this.apiResp = response;
          this.reLoadGrid.emit(true);
        } else {
          this._service.displayToastr(response);
        }
      });
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }

  isRegistrationformValid(){
   
      
    if(this.formData.basf_user_id && this.formData.firstName && this.formData.emailAddress && this.selectedBU.length>0  && this.selectedCountries.length>0){
      
      return false;
      
    }
    else {
      return true;
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.active {
    background-color: white;
    border-radius: 6px;
}


#cust-nav .navbar-light .navbar-toggler {
    background-color: white;
    margin: 2px 20px;
}

#cust-nav .navbar {
    padding: 0px !important;
    background-color: #0096d6 !important;
}

#cust-nav .cust-h6 {
    padding: 0px 5px;
    color: white;
}
#cust-nav li.nav-item.active h6 {
    color: #0096d6;
}
@media (min-width: 768px) {
    .navbar-expand-md {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

@media (min-width: 1024px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/header/header.component.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;;AAGA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI;QACI,eAAe;QACf,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,2BAA2B;IAC/B;AACJ","sourcesContent":["\r\n.active {\r\n    background-color: white;\r\n    border-radius: 6px;\r\n}\r\n\r\n\r\n#cust-nav .navbar-light .navbar-toggler {\r\n    background-color: white;\r\n    margin: 2px 20px;\r\n}\r\n\r\n#cust-nav .navbar {\r\n    padding: 0px !important;\r\n    background-color: #0096d6 !important;\r\n}\r\n\r\n#cust-nav .cust-h6 {\r\n    padding: 0px 5px;\r\n    color: white;\r\n}\r\n#cust-nav li.nav-item.active h6 {\r\n    color: #0096d6;\r\n}\r\n@media (min-width: 768px) {\r\n    .navbar-expand-md {\r\n        flex-wrap: wrap;\r\n        justify-content: flex-start;\r\n    }\r\n}\r\n\r\n@media (min-width: 1024px) {\r\n    .navbar-expand-md {\r\n        flex-wrap: nowrap;\r\n        justify-content: flex-start;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfsection{
    
    border: 2px solid #dee2e6;
    height: 700px;
  }
  .signatorysection{
    margin-top:20px;
    border: 2px solid #dee2e6;
    height: 450px;
  }

  .add-btn {
    color: white;
    background-color: #0096d6;
    margin: 10px;
    border-radius: 5px;
    float: right;
}
.closebtn{
  border-radius: 100px;
  background-color: white;
  border-color: #dee2e6;
  width:40px;
  height: 40px;
  float:right;
}
.closebtn:hover{
  background-color: #dee2e6;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/admin/contract-management-module/contract-management-module.component.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,aAAa;EACf;EACA;IACE,eAAe;IACf,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;AACA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,qBAAqB;EACrB,UAAU;EACV,YAAY;EACZ,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".pdfsection{\r\n    \r\n    border: 2px solid #dee2e6;\r\n    height: 700px;\r\n  }\r\n  .signatorysection{\r\n    margin-top:20px;\r\n    border: 2px solid #dee2e6;\r\n    height: 450px;\r\n  }\r\n\r\n  .add-btn {\r\n    color: white;\r\n    background-color: #0096d6;\r\n    margin: 10px;\r\n    border-radius: 5px;\r\n    float: right;\r\n}\r\n.closebtn{\r\n  border-radius: 100px;\r\n  background-color: white;\r\n  border-color: #dee2e6;\r\n  width:40px;\r\n  height: 40px;\r\n  float:right;\r\n}\r\n.closebtn:hover{\r\n  background-color: #dee2e6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

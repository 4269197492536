import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { FopHttpService } from './fop-http.service';

@Injectable({
  providedIn: 'root',
})
export class SharedParameterService {
  private shrd = new BehaviorSubject<Object>(new Object());

  constructor(
    private _fopHttpService: FopHttpService,
    private spinner: NgxSpinnerService
  ) {}
  getParameter(): Observable<Object> {
    return this.shrd.asObservable();
  }

  setParameter(obj: Object) {
    this.shrd.next(obj);
  }

  ExportAll() {
    this.spinner.show();
    const fopId = sessionStorage.getItem('fopId');
    let currentFop = JSON.parse(
      (sessionStorage.getItem('fop')
        ? sessionStorage.getItem('fop')
        : sessionStorage.getItem('enduserFop')) + ''
    );
    const fopTypeId: string = currentFop.fopTypeId;
    const fopCountry = currentFop.country;
    const fopType = fopTypeId.substring(2, 4);

    this._fopHttpService
      .ExportAll(fopId, fopTypeId, fopType, fopCountry)
      .subscribe((response: any) => {
        if (!response['isError']) {
          console.log(response);
          this.download(
            response.response.fileBase64str,
            response.response.fileName
          );
          if (
            response.response.attachments &&
            response.response.attachments.length > 0
          ) {
            response.response.attachments.forEach((file: any) => {
              this.download(
                file.attachmentFileBase64str,
                file.attachmentFileName
              );
            });
          }
        } else {
          this._fopHttpService.displayToastr(response);
        }
        this.spinner.hide();
      });
  }

  download(data: any, fileName: string) {
    const MIMEtype = this.getMIMEtype(fileName);
    const linkSource = `data:${MIMEtype};base64,${data}`;
    const downloadLink = document.createElement('a');
    const filename = fileName;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }

  getMIMEtype(name: string) {
    switch (name.substring(name.lastIndexOf('.') + 1).toLowerCase()) {
      case 'pdf':
        return 'application/pdf';
      case 'xlxs':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'png':
        return 'image/png';
      case 'jpg':
        return 'image/jpeg';
      default:
        return 'application/pdf';
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) { }
  private router!: Router;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let tokenInfo = sessionStorage.getItem('jwtToken');
    let lang = this.translateService.currentLang ?? sessionStorage.getItem('language') ?? 'en';

    if (!request.url.includes('add')) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json'),
        });
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json'),
        });
        request = request.clone({
          headers: request.headers.set('Cache-Control', 'no-cache'),
        });
        request = request.clone({
          headers: request.headers.set('Pragma', 'no-cache'),
        });
        request = request.clone({
          headers: request.headers.set(
            'Expires',
            'Sat, 01 Jan 2000 00:00:00 GMT'
          ),
        });
        request = request.clone({
          headers: request.headers.set('If-Modified-Since', '0'),
        });
      }
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Origin', ['https://localhost:44381', 'https://customersetup-dev.basf.com', 'https://customersetup-qa.basf.com', 'https://customersetup.basf.com', 'https://federation-qa.basf.com', 'https://federation.basf.com']),
      });
      request = request.clone({
        headers: request.headers.set('language',lang),
      });
    }
    if (tokenInfo != null)
      request = request.clone({
        headers: request.headers.set('Authorization', `${tokenInfo}`),
      });

    this.iterate(request.body);   

    return next.handle(request).pipe(
      tap({
      next:(event)=>{
        this.eventFunction(event);
      },
      error:(error)=>{
        this.errorFunction(error);
      }
    })
    )
  }

  iterate(obj: any) {
    for (let key in obj) {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        this.iterate(obj[key]);
      } else if (obj[key] !== null && typeof obj[key] === 'string') {
        if (String(obj[key]).length < 50) {
          obj[key] = obj[key].toUpperCase();
        }
      }
    }
  }

  eventFunction(event: any) {
    if (event instanceof HttpResponse) {
      console.log('Event: ', event);
    }
  }

  errorFunction(error: any) {
    if (error instanceof HttpErrorResponse) {
      console.log('api call error :', error);
      console.log('api call error status:', error.status);
      if (error.status == 401 || error.status == 0) {
        this.router.navigate(['/home']);
      }
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-customer-account-specialist',
  templateUrl: './customer-account-specialist.component.html',
  styleUrls: ['./customer-account-specialist.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerAccountSpecialistComponent implements OnInit {
  userId = parseInt(sessionStorage.getItem('userId') + '');
  cols: any;
  customerData: any;
  customerAPIResp: any;
  filteredFields: any;
  filteredData: any;
  tempFilteredData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  apiRequestObj: any = {
    custmoerAccountId: 0,
    customerAccSpecialistName: '',
    customerAccNumber: '',
    customerEmail: '',
    business_unit: [],
    modified_By: sessionStorage.getItem('userId'),
  };
  CustomerAccountId: any = 0;
  modifiedby: any = sessionStorage.getItem('userId') + '';
  customerAccountName: any = '';
  customerEmail: any = '';
  businessUnit: any = [];
  businessUnits: any = [];

  constructor(
    private service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.getAllDivisions();
    this.getAllCustomers();
  }

  getAllDivisions() {
    this.service.getAllDivisions().subscribe((response: any) => {
      if (!response['isError']) {
        this.businessUnits = response.response;
      }
    });
  }

  getAllCustomers() {
    this.spinner.show();
    this.service.getAllCustomerAccount().subscribe((response: any) => {
      if (!response['isError']) {
        this.customerAPIResp = response.response;
        this.showForm = false;
        this.customerData = JSON.parse(
          JSON.stringify(this.customerAPIResp.item1)
        );
      } else {
        this.service.displayToastr(response);
      }
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }

  onSelectRow(rowObject: any) {
    this.businessUnit = [];
    this.showForm = true;
    this.CustomerAccountId = rowObject['custmoerAccountId'];
    this.modifiedby = rowObject['modified_By'];
    this.customerAccountName = rowObject['customerAccSpecialistName'];
    this.customerEmail = rowObject['customerEmail'];
    const BUs = this.customerAPIResp?.item2.filter(
      (bu: any) => bu.id == rowObject['custmoerAccountId']
    );
    BUs?.forEach((bu: any) => {
      this.businessUnit.push(bu.businessUnit_Id);
    });
  }

  deleteRow(rowObject: any) {
    this.CustomerAccountId = rowObject.custmoerAccountId;
    this.modifiedby = this.userId.toString();
    this.service
      .DeleteCustomerAccount(this.CustomerAccountId, this.modifiedby)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.service.displayToastr(this.apiRequestObj);
          this.getAllCustomers();
        } else {
          this.service.displayToastr(response);
        }
      });
  }

  submit() {
    this.spinner.show();
    this.apiRequestObj['custmoerAccountId'] = this.CustomerAccountId;
    this.apiRequestObj['customerAccSpecialistName'] = this.customerAccountName;
    this.apiRequestObj['customerEmail'] = this.customerEmail;
    this.apiRequestObj['modified_By'] = this.modifiedby;
    let businessUnitData: any[] = [];
    this.businessUnit.forEach((bu: any) => {
      let bObj = {
        id: 0,
        userId: this.apiRequestObj['custmoerAccountId'],
        businessUnit_Id: bu,
      };
      businessUnitData.push(bObj);
    });
    this.apiRequestObj['business_unit'] = businessUnitData;
    this.service
      .createOrUpdateCustomerAccount(this.apiRequestObj)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.customerData = JSON.parse(JSON.stringify(this.apiRequestObj));
          this.service.displayToastr(this.customerData);
          this.clear();
          this.getAllCustomers();
        } else {
          this.service.displayToastr(response);
        }
      });
    this.spinner.hide();
  }

  clear() {
    this.CustomerAccountId = 0;
    this.customerAccountName = '';
    this.customerEmail = '';
    this.businessUnit = [];
    this.modifiedby = '0';
  }
  cancel() {
    this.clear();
    this.showForm = false;
  }

  addNewCustomer() {
    this.showForm = true;
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  languages: string[];  
  currentLanguage: string;
  constructor(private translate: TranslateService, private router: Router) {
    this.languages = this.translate.getLangs();
    this.currentLanguage = sessionStorage.getItem('language') ?? this.translate.currentLang;
  }  
  onLanguageChange(selectedLang: string) {
    this.translate.use(selectedLang);
    sessionStorage.setItem('language', selectedLang);
    this.redirectTo(this.router.url);
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }
}

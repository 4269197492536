import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-test-login',
  templateUrl: './test-login.component.html',
})
export class TestLoginComponent implements OnInit {
  basfCCusers: any;
  selectedUser: any;
  selectedGridRecord: any;
  businessUnits: any;
  countries: any;

  constructor(private service: FopHttpService, private router: Router) {
    console.log('login test component');
  }

  ngOnInit(): void {
    this.getAllDivisionsAndCountries();
    this.getAllUsers();
  }

  getAllDivisionsAndCountries() {
    this.service.getAllDivisions().subscribe((response: any) => {
      if (!response['isError']) {
        this.businessUnits = response.response;
      }
    });
    this.service.getAllCountries().subscribe((response: any) => {
      if (!response['isError']) {
        this.countries = response.response;
      }
    });
  }

  getAllUsers() {
    this.service.getAllUsers().subscribe((response: any) => {
      if (!response['isError']) {
        this.basfCCusers = JSON.parse(JSON.stringify(response.response.item1));
        this.basfCCusers.forEach((u: any) => {
          u=this.handleBusinessUnitsandCountries(u,response);
          console.log(u)          
        });
      } else {
        this.service.displayToastr(response);
      }
    });
  }

  handleBusinessUnitsandCountries(user:any,response: any){
    let fBusinesUnits = response.response.item2.filter(
      (ua: any) => ua.userId === user.user_id
    );
    if (fBusinesUnits.length > 0) {
      let tBUnit = '';
      fBusinesUnits.forEach((fb: any) => {
        let fbUnit = this.businessUnits?.find(
          (bu: any) => bu.divisionId === fb.businessUnit_Id
        );
        if (tBUnit == '') {
          if (fbUnit?.['divisionName']) {
            tBUnit = fbUnit?.['divisionName'];
          }
        }
        else {
          tBUnit = tBUnit + ',' + fbUnit?.divisionName;
        }
      });
      user.businessUnits = tBUnit;
    }
    else {
      user.businessUnits = '';
    }
    return this.handleCountries(user,response);
  }

  handleCountries(user:any,response: any){
    let Countries = response.response.item3.filter(
      (c: any) => c.userId === user.user_id
    );
    if (Countries.length > 0) {
      let tcountry = '';
      Countries.forEach((fc: any) => {
        let fcUnit = this.countries?.find(
          (cu: any) => cu.country_id === fc.countryId
        );
        if (tcountry == '') {
          if (fcUnit?.['country_Code']) {
            tcountry = fcUnit?.['country_Code'];
          }
        } else {
          tcountry = tcountry + ',' + fcUnit?.country_Code;
        }
      });
      user.countries = tcountry;
    }
    return user;
  }

  loginbtn() {
    if (!this.selectedUser) {
      this.service.displayToastr({
        isError: false,
        errorMessage: 'Select an user to continue.',
      });
      return;
    }
    this.basfCCusers.forEach((user: any) => {
      if (user.emailAddress == this.selectedUser) {
        this.selectedGridRecord = user;
      }
    });

    sessionStorage.setItem(
      'loginuseremail',
      this.selectedGridRecord.emailAddress
    );
    sessionStorage.setItem(
      'jwtToken',
      btoa(this.selectedGridRecord.emailAddress)
    );
    sessionStorage.setItem(
      'UserToken',
      JSON.stringify(this.selectedGridRecord)
    );
    sessionStorage.setItem(
      'isAdmin',
      String(this.selectedGridRecord.is_admin).toLowerCase()
    );
    sessionStorage.setItem(
      'isContractManager',
      String(this.selectedGridRecord.is_contract_manager).toLowerCase()
    );
    sessionStorage.setItem('userId', this.selectedGridRecord.user_id);
    sessionStorage.setItem(
      'isExternalUser',
      'false'
    );

    this.service.sendAdminValue(String(this.selectedGridRecord.is_admin));
    this.service.sendIsExternalUser(
      String(this.selectedGridRecord.IsExternalUser)
    );

    this.router.navigate(['/home']);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup = {} as UntypedFormGroup;
  submitted = false;
  islogin: boolean = false;
  username: any;

  adminAccess: boolean = false;
  loginvalue: any;
  responsefromJWT: any;
  expireDate: any;
  logout: any;
  params: any = { Username: '', Email: '', IsAdmin: '', userid: 0 };
  
  ngOnInit() {
    if (sessionStorage.getItem('logout')) {
      this.logout = JSON.parse(sessionStorage.getItem('logout') + '');
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';  

import { Subject } from 'rxjs';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';
import {
  ShippingPayload,
  ShippingRequirementsHelper,
} from '../shipping.requirements.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-package-truck-request',
  templateUrl: './package-truck-request.component.html',
  styleUrls: ['./package-truck-request.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class PackageTruckRequestComponent implements OnInit {
  InvalidEmailsListcofA = '';
  InvalidEmailsListcontactASN = '';
  @Input() submitFopTriggered!: Subject<string>;
  @Input() savedFormData: any;
  @Input() incoTerms: any;
  @Input() disableForm: any;
  @Output() closeFormEvent = new EventEmitter();
  @Output() submitFormEvent = new EventEmitter();
  @Output() getExistingShippingData = new EventEmitter();
  @Output() deleteForm = new EventEmitter();
  @Output() exportPkgFormData = new EventEmitter();
  enduserFop: any = sessionStorage.getItem('enduserFop');
  coa: any = sessionStorage.getItem('coa');
  asn: any = sessionStorage.getItem('asn');
  coaEmail: any = sessionStorage.getItem('coaEmail');
  asnEmail: any = sessionStorage.getItem('asnEmail');
  submitted: boolean = false;
  packageRequestDetails: UntypedFormGroup = {} as UntypedFormGroup;
  masterData: any;
  fopType: string | null = '';
  plantHolidayShdlDD: any = [];
  yesOrNo = ['NA', 'Yes', 'No'];
  fopReadyToGO = sessionStorage.getItem('fopReadyToGO');
  shippingData: ShippingPayload = {} as ShippingPayload;
  @Input() companyCityStateOfShippingAddress: any[] = [];
  @Input() materialNumberList: any = [];
  fopId = sessionStorage.getItem('fopId');
  endUserBusiness: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _fopHttpService: FopHttpService,
    private _sharedParameter: SharedParameterService,
    private _shippingHelper: ShippingRequirementsHelper,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    this.coa = this.yesOrNo[parseInt(this.coa ? this.coa : '0')];
    this.asn = this.yesOrNo[parseInt(this.asn ? this.asn : '0')];

    this.packageRequestDetails = this.formBuilder.group({
      isReqFiledsFilled: [false],
      shippingID: 0,
      foP_Id: parseInt(sessionStorage.getItem('fopId') + ''),
      userId: [sessionStorage.getItem('userId')],
      receivingContact: this.formBuilder.group({
        multiCompanyCityState: this.formBuilder.group({
          companyName: ['', Validators.required],
          city: ['', Validators.required],
          state: ['', Validators.required],
        }),
        companyname: ['', Validators.required],
        destinationcity: ['', Validators.required],
        destinationstate: ['', Validators.required],
        name: ['', Validators.required],
        phoneNo: [
          '',
          [
            Validators.required,
            Validators.pattern('[- +()0-9]+'),
            Validators.minLength(10),
            Validators.maxLength(17),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        fax: [''],
        receivingHrsFrom: ['', Validators.required],
        receivingHrsTo: ['', Validators.required],
        plantHolidayShdl: ['', Validators.required],
        contactCOA: ['', Validators.required],
        contactASN: ['', Validators.required],
        customerProductCode: [''],
        normalOrderQty: [''],
        liquidDryBulk: null,
        shipMaxNumberSlots: [''],
        estimatedAnnualQuantity: ['', Validators.required],
        materialDetails: this.formBuilder.array([
          this.formBuilder.group({
            materialNumber: [''],
            materialName: [''],
          }),
        ]),
      }),
      palletRequirements: this.formBuilder.group({
        palletizingReq: [0, Validators.required],
        shrinkWrapReq: [0, Validators.required],
        palletJacketReq: [0, Validators.required],
        otherPalletRequirement: [''],
      }),
      equipmentDeliveryShipping: this.formBuilder.group(
        {
          flatbedTruckRequired: [0, Validators.required],
          sizeTrapRequired: [''],
          liftGateRequired: [0, Validators.required],
          protectFreezeRequired: 0,
          minFahrenheitCelsius: [''],
          customerCode: [''],
          customerPurchaseOrder: [''],
          shipppingOther: [''],
        },
        { validators: sizeTrapValidator }
      ),
      deliveryTime: this.formBuilder.group(
        {
          ftlRtlDeliveryApptReq: [0, Validators.required],
          receivingHrs: [''],
          contactForDeliveryAptName: [''],
          contactForDeliveryAptPhone: [
            '',
            [
              Validators.pattern('[- +()0-9]+'),
              Validators.minLength(10),
              Validators.maxLength(17),
            ],
          ],
          daysEarlyfromDeliveryDt: [''],
          dayslaterfromDeliveryDt: [''],
        },
        { validators: deliveryTimeValidator }
      ),
      prefferredCarrier: this.formBuilder.group({
        carrierContactName: [''],
        carrierContactPhone: [''],
        carrierContactEmail: [''],
      }),
      otherRequirements: [''],
    });
    this.fopType = localStorage.getItem('basicFopTypeId');
    this.masterData = this._fopHttpService.getSavedMasterData();
    if (this.masterData) {
      for (const element of this.masterData) {
        if (element['masterKey'] == 'PUBLIC HOLIDAYS')
          this.plantHolidayShdlDD = element['masterkeyDataList'];
      }
    }
    this.translate.stream('ALL_FOP').subscribe((resp) => {
      this.initializeTabItems();
    });
  }

  initializeTabItems() {
    this.yesOrNo = [
      'NA',
      this.translate.instant('YES'),
      this.translate.instant('NO'),
    ];
  }

  getValueOfSelectedMaterial(index: number) {
    if (index >= 0) {
      let temparray: any = this.packageRequestDetails
        .get('receivingContact')
        ?.get('materialDetails') as UntypedFormArray;
      let matNum = temparray?.value[index].materialNumber;

      if (matNum) {
        let val: any = this.materialNumberList.find(
          (k: any) => k.materialNumber == matNum
        );
        if (val?.materialName) {
          return val.materialName;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    this.initializeTabItems();
    
    if (this.enduserFop!= null || this.enduserFop != undefined) {
      console.log(this.enduserFop)
      this.endUserBusiness =JSON.parse(this.enduserFop).businessUnit;
      if(JSON.parse(this.enduserFop).businessUnit !='AP')

      this.packageRequestDetails.get('normalOrderQty')?.setValidators(Validators.required);
    }
    if (this.fopType != '4') {
      this.packageRequestDetails.controls['receivingContact']
        .get('companyname')
        ?.clearValidators();
      this.packageRequestDetails.controls['receivingContact']
        .get('destinationcity')
        ?.clearValidators();
      this.packageRequestDetails.controls['receivingContact']
        .get('destinationstate')
        ?.clearValidators();
    } else {
      this.packageRequestDetails.controls['receivingContact']
        .get('multiCompanyCityState')
        ?.get('companyName')
        ?.clearValidators();
      this.packageRequestDetails.controls['receivingContact']
        .get('multiCompanyCityState')
        ?.get('city')
        ?.clearValidators();
      this.packageRequestDetails.controls['receivingContact']
        .get('multiCompanyCityState')
        ?.get('state')
        ?.clearValidators();
    }

    this._shippingHelper
      .getShippingData()
      .subscribe((data: ShippingPayload) => {
        this.shippingData = data;
      });    

    let frm = this.packageRequestDetails.controls['receivingContact'];
    this._sharedParameter.getParameter().subscribe((k: any) => {
      //  //console.log(k,k.companyNameShipToAddress);

      frm.get('companyname')?.patchValue(k.ShipCompanyName);
      frm.get('destinationcity')?.patchValue(k.ShipDestinationCity);
      frm.get('destinationstate')?.patchValue(k.ShipDestinationState);
    });

    if (this.savedFormData) {
      this.bindValuesForSavedFormData();
    }

    if (this.coa == 'Yes') {
      this.packageRequestDetails.patchValue({
        receivingContact: {
          contactCOA: this.coaEmail,
        },
      });
    } else {
      this.packageRequestDetails.controls['receivingContact']
        .get('contactCOA')
        ?.clearValidators();
    }
    if (this.asn == 'Yes') {
      this.packageRequestDetails.patchValue({
        receivingContact: {
          contactASN: this.asnEmail,
        },
      });
    } else {
      this.packageRequestDetails.controls['receivingContact']
        .get('contactASN')
        ?.clearValidators();
    }
    if (this.disableForm || this.fopReadyToGO === 'yes') {
      this.packageRequestDetails.disable();
    }

    this.submitFopTriggered.subscribe((value) => {
      this.saveFormData();
    });
  }

  bindValuesForSavedFormData(){
    this.packageRequestDetails.patchValue(this.savedFormData);
      this.submitted = true;
      console.log(this.savedFormData);
      if (this.savedFormData.receivingContact) {
        console.log('pckg');
        if(this.savedFormData.receivingContact.contactASN){
          let savedAsnEmail=this.savedFormData.receivingContact.contactASN;
          this.asnEmail=(savedAsnEmail!=undefined && savedAsnEmail!='')?savedAsnEmail:this.asnEmail;
        }
        if(this.savedFormData.receivingContact.contactCOA){
          let savedCOAEmail=this.savedFormData.receivingContact.contactCOA;
          this.coaEmail=(savedCOAEmail!=undefined && savedCOAEmail!='')?savedCOAEmail:this.coaEmail;
        }
        this.BindCompanyCityStateToForm();
      }
      this.InitializeMaterialFormArray();
  }

  BindCompanyCityStateToForm() {
    let dd = this.savedFormData.receivingContact.multiCompanyCityState as any[];
    let frm = this.packageRequestDetails.get('receivingContact');
    if (dd && dd.length > 0) {
      let ids = dd.map((k) => k.addressId);
      frm?.patchValue({
        multiCompanyCityState: {
          companyName: ids,
          city: ids,
          state: ids,
        },
      });
    }
  }

  onSelectCompany(event: any) {
    let id = event.value;
    console.log(id);
    console.log(this.packageRequestDetails.value);

    let cmp = this.packageRequestDetails
      .get('receivingContact')
      ?.get('multiCompanyCityState');

    cmp?.get('city')?.patchValue(id);
    cmp?.get('state')?.patchValue(id);
  }

  MapCompanyCityState() {
    let cmp =
      this.packageRequestDetails.value.receivingContact.multiCompanyCityState;
    let ret: any[] = [];
    let id = cmp?.companyName as any[];
    if (id && id.length > 0) {
      id.forEach((k: string) => {
        let m = this.companyCityStateOfShippingAddress.find(
          (p) => p.addressId == k
        );

        if (m) {
          ret.push({
            addressId: m.addressId,
            companyName: m.companyName,
            city: m.city,
            state: m.state,
          });
        }
      });
    }

    return ret;
  }
  get f() {
    return this.packageRequestDetails.controls as any;
  }

  deleteItem() {
    let shippingID: number = this.savedFormData.shippingID;
    if (shippingID == 0) {
      this.deleteForm.emit();
    } else {
      this._fopHttpService
        .deleteShiipingData(this.fopId, shippingID)
        .subscribe((response: any) => {
          let IsError: boolean = response.IsError;
          if (!IsError) {
            this.deleteForm.emit();
          }
        });
    }
  }

  saveFormData() {
    if (!this.packageRequestDetails.value.foP_Id) {
      return;
    }
    if (this.fopReadyToGO === 'yes') {
      return;
    }
    this.submitted = true;
    this.packageRequestDetails.value.isReqFiledsFilled =
      !this.packageRequestDetails.invalid;
    this.packageRequestDetails.value.receivingContact.multiCompanyCityState =
      this.MapCompanyCityState();
    const findExisting = this.shippingData.packageTruckRequests?.find(
      (o: any) =>
        o.shippingID == this.packageRequestDetails.value.shippingID &&
        this.packageRequestDetails.value.shippingID != 0
    );
    let newCustomerObj: any;
    if (findExisting) {
      newCustomerObj = this.packageRequestDetails.value;
      newCustomerObj.materialDetails =
        this.mapMaterialListiWithFormGroup(newCustomerObj);

      newCustomerObj.isReqFiledsFilled = !this.packageRequestDetails.invalid;
      this.shippingData.packageTruckRequests[
        this.shippingData.packageTruckRequests.indexOf(findExisting)
      ] = newCustomerObj;
    } else {
      newCustomerObj = this.packageRequestDetails.value;
      newCustomerObj.materialDetails =
        this.mapMaterialListiWithFormGroup(newCustomerObj);

      newCustomerObj.isReqFiledsFilled = !this.packageRequestDetails.invalid;
      if (this.shippingData.packageTruckRequests) {
        this.shippingData.packageTruckRequests.push(newCustomerObj);
      } else {
        this.shippingData.packageTruckRequests = [newCustomerObj];
      }
      this.packageRequestDetails.reset();
    }
    this.exportPkgFormData.emit(newCustomerObj);
    this._shippingHelper.sendShippingData(this.shippingData);
  }

  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }
  addMaterial(): void {
    const materialForm = this.formBuilder.group({
      materialNumber: [''],
      materialName: [''],
    });
    this.GetMaterialFormArray.push(materialForm);
  }
  removeMaterial(index: number): void {
    this.GetMaterialFormArray.removeAt(index);
    let itm = this.savedFormData.materialDetails as any[];
    itm.splice(index, 1);
    this.savedFormData.materialDetails = itm;
  }
  InitializeMaterialFormArray() {
    let frm = this.packageRequestDetails
      .get('receivingContact')
      ?.get('materialDetails') as UntypedFormArray;

    if (this.savedFormData) {
      let matdet = this.savedFormData.materialDetails as any[];
      if (matdet != undefined && matdet.length > 0) {
        frm.clear();
        for (const iterator of matdet) {
          let ctrl = this.formBuilder.group({
            materialNumber: [iterator.materialNumber],
            materialName: [iterator.materialName],
          });
          frm.push(ctrl);
        }
      }
    }
  }
  mapMaterialListiWithFormGroup(newCustomerObj: any): any {
    let val = newCustomerObj.receivingContact.materialDetails as any[];
    let newval: any[] = [];

    if (this.savedFormData) {
      let matdet = this.savedFormData.materialDetails as any[];

      for (let i = 0; i < val.length; i++) {
        let mat =
          matdet != undefined && matdet.length > 0 ? matdet[i] : undefined;
        if (mat) {
          newval.push({
            id: mat.id,
            materialNumber: val[i].materialNumber,
            materialName: val[i].materialName,
          });
        } else
          newval.push({
            id: 0,
            materialNumber: val[i].materialNumber,
            materialName: val[i].materialName,
          });
      }
    }
    return newval;
  }
  get GetMaterialFormArray() {
    return this.packageRequestDetails
      .get('receivingContact')
      ?.get('materialDetails') as UntypedFormArray;
  }
  validateEmailMultipleID(getPaperworkField: any) {
    if (
      getPaperworkField ==
      this.packageRequestDetails.value.receivingContact.contactCOA
    ) {
      let emailsSeparator =
        this.packageRequestDetails.value.receivingContact.contactCOA.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);
      this.InvalidEmailsListcofA = '' + invalidEmails;
    }
    if (
      getPaperworkField ==
      this.packageRequestDetails.value.receivingContact.contactASN
    ) {
      let emailsSeparator =
        this.packageRequestDetails.value.receivingContact.contactASN.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListcontactASN = '' + invalidEmails;
    }
  }
  returnEmail(emailsSeparator: any, invalidEmails: any) {
    for (const iterator of emailsSeparator) {
      if (!this.validateEmailmultiple(iterator.trim())) {
        invalidEmails.push(iterator.trim());
      }
    }
  }
  validateEmailmultiple(emailsSeparator: any) {
    let re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return re.test(emailsSeparator);
  }
  coaAsnChange(value: any, type: string) {
    sessionStorage.setItem(type, value);
  }
}

export const sizeTrapValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const flatbedTruckRequired = control.get('flatbedTruckRequired');
  const sizeTrapRequired = control.get('sizeTrapRequired');

  return flatbedTruckRequired?.value && sizeTrapRequired?.value === ''
    ? { sizeTrapRequired: true }
    : null;
};

export const deliveryTimeValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const ftlRtlDeliveryApptReq = control.get('ftlRtlDeliveryApptReq');
  const receivingHrs = control.get('receivingHrs');
  const contactForDeliveryAptName = control.get('contactForDeliveryAptName');
  const contactForDeliveryAptPhone = control.get('contactForDeliveryAptPhone');

  return ftlRtlDeliveryApptReq?.value &&
    (receivingHrs?.value === '' ||
      contactForDeliveryAptName?.value === '' ||
      contactForDeliveryAptPhone?.value === '')
    ? {
        receivingHrs: receivingHrs?.value === '',
        contactForDeliveryAptName:
          contactForDeliveryAptName?.value === '' ,
        contactForDeliveryAptPhone:
          contactForDeliveryAptPhone?.value === '',
      }
    : null;
};

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-new-customer-grid',
  templateUrl: './new-customer-grid.component.html',
  styleUrls: ['./new-customer-grid.component.css'],
})
export class NewCustomerGridComponent implements OnInit {
  constructor(
    private _fopHttpService: FopHttpService,
    private router: Router
  ) {}

  userId = sessionStorage.getItem('userId') + '';
  gridData: any;

  ngOnInit(): void {
    this.getCustomerGridData();
  }

  getCustomerGridData() {
    this._fopHttpService
      .getAllCustomersByUserId(this.userId)
      .subscribe((response: any) => {
        this.gridData = response.response;
      });
  }

  viewRecord(rowData: any) {
    let path = 'admin/new-customer';
    this.router.navigate([path], { state: { data: { rowData: rowData } } });
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-contract-document',
  templateUrl: './customer-contract-document.component.html',
  styleUrls: ['./customer-contract-document.component.css']
})
export class CustomerContractDocumentComponent implements OnInit {
  @Output() setActiveItem = new EventEmitter();
  @Output() closeFormEvent = new EventEmitter();

  userId = parseInt(sessionStorage.getItem('userId') + '');
 
  fopTypeId: any;
  accountNumber: any;
  customerAccountSpecialist: any;
  customerAccountSpecialistDD: any;
  basfCCfop:any;
  enduserFop:any;

  pdfContent:any;
  fileObject:any;
  displayPdf:boolean=false;
  fopId:any;
  retailerForm!:UntypedFormGroup;
  submitted: boolean = false;
  signed:boolean=false;


  displayReadyToGoDialog: boolean = false;
  PENDING_WITH_CUSTOMER = 'Pending With Customer';
  COMMENTS_REQUIRED_TO_RESEND_FOR_CORRECTION =
    'COMMENTS_REQUIRED_TO_RESEND_FOR_CORRECTION';
  PLEASE_SELECT_ACCOUNT_NUMBER_AND_CUSTOMER_SPECIALIST =
    'PLEASE_SELECT_ACCOUNT_NUMBER_AND_CUSTOMER_SPECIALIST';
    comments: string = '';

  constructor(private fb:UntypedFormBuilder,private fopservice:FopHttpService,
              private spinner: NgxSpinnerService,private translate:TranslateService) {
    this.basfCCfop = sessionStorage.getItem('fop');
    this.enduserFop = sessionStorage.getItem('enduserFop');
    this.fopId = parseInt(sessionStorage.getItem('fopId') + '');
    
    if (this.basfCCfop) {
      this.basfCCfop = JSON.parse(this.basfCCfop);
    }
    if (this.enduserFop) {
      this.enduserFop = JSON.parse(this.enduserFop);
    }

    if (this.basfCCfop?.fopId) {
      this.fopTypeId = this.basfCCfop?.fopTypeId;
    } else {
      this.fopTypeId = this.enduserFop?.fopTypeId;
    }
   }

  ngOnInit(): void {
    this.retailerForm=this.fb.group({
      retailerName:['',Validators.required],
      retailerAddress:['',Validators.required],
      city:['',Validators.required],
      state:['',Validators.required],
      zipcode:['',Validators.required]
    })
    this.getContractDocument();
    this.getCustomerAccountSpecialistData();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.retailerForm.controls;
  }

  getContractDocument(){
    this.fopservice.getCustomerContractDocument(this.fopId).subscribe((response:any)=>{
      this.fileObject=response.response;
      this.signed=this.fileObject.isSigned;
      console.log(this.fileObject);
      const source=`data:${this.fileObject.mimeType.toLowerCase()};base64,${this.fileObject.fileContentString}`;
      this.pdfContent=source;
      this.displayPdf=true;
    })
  }
  activeIndex(value: any) {
    this.setActiveItem.emit(value);
  }
  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }
  closeDialog() {
    this.displayReadyToGoDialog = false;
  }

  getCustomerAccountSpecialistData() {
    this.fopservice
      .getCustomerAccountSpecialistData(this.fopId)
      .subscribe((response: any) => {
        if (response.isError) {
          console.log(response);
        }
        this.customerAccountSpecialistDD = response.response;
      });
  }

  readyToGo() {
    if (!this.accountNumber || !this.customerAccountSpecialist) {
      this.fopservice.displayToastr({
        isError: true,
        response: this.translate.instant(
          this.PLEASE_SELECT_ACCOUNT_NUMBER_AND_CUSTOMER_SPECIALIST
        ),
      });
      return;
    }
    this.spinner.show();
    const params = {
      fopId: this.fopId,
      fopTypeId: this.fopTypeId,
      accountNo: this.accountNumber,
      accountSpecialist: this.customerAccountSpecialist.customerSpecialistName,
      accountSpecialistId: this.customerAccountSpecialist.customerSpecialistId,
      userId: this.userId,
    };
    this.fopservice.readyToGo(params).subscribe((response: any) => {
      if (!response['isError']) {
        this.basfCCfop.status = 'Ready To GO';
        sessionStorage.setItem('fop', JSON.stringify(this.basfCCfop));
      }
      this.displayReadyToGoDialog = false;
      this.spinner.hide();
      this.fopservice.displayToastr(response);
    });
  }

  checkFormValidation(){
    if(this.retailerForm.invalid)
    {
      this.retailerForm.markAllAsTouched();
      return false;
    }
    else return true;
  }

  onSubmit(signatorydetails:any){    
    if(!this.checkFormValidation())
       return;

    this.spinner.show();
    this.displayPdf=false;
    let payload=
    {
      "fopid": this.fopId,
      "companyDetails": {
        "retailerName": this.retailerForm.value["retailerName"],
        "retailerAddress": this.retailerForm.value["retailerAddress"],
        "city": this.retailerForm.value["city"],
        "state": this.retailerForm.value["state"],
        "zipcode": this.retailerForm.value["zipcode"]
      },
      "signatoryDetails": {
        "name": signatorydetails.adminName,
        "title": signatorydetails.adminTitle,
        "date": signatorydetails.agreementDate,
        "signatureImage": signatorydetails.adminSignatureImage
      },
      "pdfFile": {
        "id": this.fileObject.id,
        "fileName": this.fileObject.fileName,
        "mimeType": this.fileObject.mimeType,
        "fileContentString": this.fileObject.fileContentString
      }
    }   
    
    this.fopservice.updateCustomerContractDocument(payload).subscribe(response=>{
      this.retailerForm.reset();
      this.fopservice.displayToastr(response);
      this.getContractDocument();
      this.spinner.hide();
    })
    
  }


  submitFOP() {
    this.fopservice
      .submitFOP(
        this.enduserFop?.fopId,
        this.enduserFop?.fopTypeId,
        this.userId
      )
      .subscribe((resp: any) => {
        if (!resp['isError']) {
          this.enduserFop.status = 'Submitted';
          sessionStorage.setItem('enduserFop', JSON.stringify(this.enduserFop));
          this.closeForm();
        }
        this.fopservice.displayToastr(resp);
      });
  }

  
  reviewFopRequest(type: string) {
    this.spinner.show();
    let request = {
      fopId: this.fopId,
      fopTypeId: this.fopTypeId,
      submitFlag: 'Resend To Customer',
      reviewComments: '',
      submittedBy: this.userId,
    };
    if (type === 'confirm') {
      request.submitFlag = 'Complete';
      if (this.comments.length > 1) {
        request.reviewComments = this.comments;
      } else {
        request.reviewComments = 'Reviewed FOP; Marked as Complete.';
      }
    } else {
      request.submitFlag = 'Resend To Customer';
      if (this.comments.length < 1) {
        this.fopservice.displayToastr({
          isError: true,
          response: this.translate.instant(
            this.COMMENTS_REQUIRED_TO_RESEND_FOR_CORRECTION
          ),
        });
        return;
      }
      request.reviewComments = this.comments;

      // save comment to show in history and comments tab
      let requestObj = {
        fop_Id: this.fopId,
        comment_Id: 0,
        comments: this.comments,
        parentId: 0,
        user: this.userId.toString(),
        date: new Date(),
        repliesCount: 0,
      };
      this.fopservice
        .CreateComments(requestObj)
        .subscribe((response: any) => {
          if (response.isError) {
            this.fopservice.displayToastr(response);
          }
        });
    }
    this.fopservice
      .reviewFopRequest(request)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.basfCCfop.status = type === 'confirm' ? 'Approved' : 'Draft';
          sessionStorage.setItem('fop', JSON.stringify(this.basfCCfop));
        }
        this.spinner.hide();
        this.fopservice.displayToastr(response);
      });
  }
  

}

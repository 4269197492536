import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit {
  items!: MenuItem[];
  activeItem: any;
  displayDialog: boolean = false;
  userId = parseInt(sessionStorage.getItem('userId') + '');
  customersDD: any = [];
  usersDD: any = [];
  selectedCustomer: any;
  users: any = [];
  selectedUser: any;
  isTabItemsloaded:boolean=false;
  isContractManager:boolean=false;
  isDivisionAllowed:boolean=false;
  constructor(
    private _fopHttpService: FopHttpService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private router: Router
  ) {
    //  Admin component
  }

  ngOnInit(): void {
    this.spinner.show();
    this.isContractManager=sessionStorage.getItem('isContractManager')=='true';
    this.getUserDetails();       
  }

  getUserDetails(){
    this._fopHttpService.getUserDetailsBasedOnId(this.userId).subscribe((user:any)=>{
      let userBu:any[]=[]       
      userBu=user.response.item2;
      userBu.forEach((bu:any)=>{
          if(bu.businessunit_name=="AP")
            this.isDivisionAllowed=true;
       });       
       console.log(this.isDivisionAllowed,"checking isdivision");      
      this.initializetabItems();
      
    });
  }
  initializetabItems(){
    this.items = [
      {
        label: this.translate.instant('CUSTOMER'),
        command: (event1) => {
          this.activeItem = event1.item;
        },
      },
      {
        label: this.translate.instant('USERS'),
        command: (event2) => {
          this.activeItem = event2.item;
        },
      },
      {
        label: this.translate.instant('MASTER_DATA'),
        command: (event3) => {
          this.activeItem = event3.item;
        },
      },
      {
        label: this.translate.instant('COUNTRY_DATA'),
        command: (event4) => {
          this.activeItem = event4.item;
        },
      },
      {
        label: this.translate.instant('CUSTOMER_ACCOUNT_SPECIALIST'),
        command: (event5) => {
          this.activeItem = event5.item;
        },
      },
      {
        label: this.translate.instant('DIVISION_DATA'),
        command: (event6) => {
          this.activeItem = event6.item;
        },
      },
      {
        label: this.translate.instant('SBU'),
        command: (event7) => {
          this.activeItem = event7.item;
        },
      }      
      
    ];  
     if(this.isDivisionAllowed){
        if(this.isContractManager){
          this.items.push({
            label: this.translate.instant('CONTRACT_MANAGEMENT'),
            command: (event8:any) => {
            this.activeItem = event8.item;
            },
          });
        }
        this.items.push({
          label: this.translate.instant('DOCUMENTATION'),
          command: (event9:any) => {
          this.activeItem = event9.item;
          },
        });          
      }
    this.activeItem = this.items[0];
    this.isTabItemsloaded=true;
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this._fopHttpService.getSavedMasterData();
  }

  customerChange() {
    this.usersDD = this.users.filter(
      (user: any) => user.customerId === this.selectedCustomer
    );
  }

  getCustomerDetails() {
    this._fopHttpService
      .getCustomerDetails(this.userId)
      .subscribe((response: any) => {
        const resp = response.response;
        this.customersDD = resp.customers;
        this.users = resp.users;
      });
  }

  navigateTo() {
    this.router.navigate(['/admin/new-customer']);
  }

  showCreateFopDialog() {
    this.displayDialog = true;
    this.getCustomerDetails();
  }
  closeDialog() {
    this.displayDialog = false;
  }

  initiateFOP() {
    let fopUsers: any[] = [];
    this.selectedUser.forEach((user: any) => {
      fopUsers.push({
        userId: user.userId,
        email: user.email,
      });
    });
    let requestObj = {
      customerId: this.selectedCustomer,
      businessUnitId: 1,
      createdBy: 1,
      fopUsers: fopUsers,
    };

    this._fopHttpService
      .initiateNewFOP(requestObj)
      .subscribe((response: any) => {
        this._fopHttpService.displayToastr(response);
        this.displayDialog = false;
      });
  }
}

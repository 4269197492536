import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FopHttpService } from 'src/app/services/fop-http.service';
import * as FileSaver from 'file-saver';
import { MenuItem } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  @Input() fopType: any;
  userId = parseInt(sessionStorage.getItem('userId') + '');
  isExternalUser: boolean = JSON.parse(
     sessionStorage.getItem('isExternalUser') + ''
   );
  gridData: any;
  filteredGridData: any;
  selectedGridRecord: any;
  newFopType: string = '';
  soldToId: string = '';
  displayDialog: boolean = false;
  fopStatus: any = {
    Initiated: 'Pending With Customer',
    Draft: 'Pending With Customer',
    Submitted: 'Pending With CC',
    Approved: 'Completed',
    Ready_To_Go: 'Completed',
    Correction_Required: 'Pending With Customer',
  };
  GridFilterTabs!: MenuItem[];
  activeItem: any;
  // Create fop
  displayNewFopDialog: boolean = false;
  customersDD: any = [];
  usersDD: any = [];
  selectedCustomer: any;
  users: any = [];
  selectedUser: any;
  dialogHeader: any;
  currentLang: any;
  selectedyesnoDD: any='';
  yesnoDD:any[]= [
    { name: 'Yes', code: 1 },
    { name: 'No', code: 0 },
  ];
  typeOfContactDD:any[] =[];
  selectedtypeofContract:any ='';
  
  isIncludeContract:boolean=false;
  isContractEnabled:boolean=false;
  readyToGo:any='';
  NO_GRID_DATA_AVAILABLE_TO_EXPORT_AS_EXCEL = 'NO_GRID_DATA_AVAILABLE_TO_EXPORT_AS_EXCEL';
  constructor(
    private _fopHttpService: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.readyToGo=this.translate.instant('READY_TO_GO');
    if (!this.userId || this.userId.toString.length < 1) {
      sessionStorage.setItem('userId', '1');
      sessionStorage.setItem('isAdmin', 'true');
      this.userId = 1;
    }
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  ngOnInit() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    sessionStorage.removeItem('shipSelectedReq');
    sessionStorage.removeItem('shipsavedFormData');
    if (!sessionStorage.getItem('userId')) {
      sessionStorage.setItem('userId', '1');
      sessionStorage.setItem('isAdmin', 'true');
    }
    this.initializeTabItems();
    this.getFOPGridData();
  }

  initializeTabItems() {
    this.currentLang = this.translate.currentLang;
    this.GridFilterTabs = [
      {
        label: this.translate.instant('ALL_FOP'),
        command: (event) => {
          this.activeItem = event.item;
          this.filteredGridData = this.gridData;
        },
      },
      {
        label: this.translate.instant('PENDING_WITH_CUSTOMER'),
        command: (event) => {
          this.activeItem = event.item;
          this.filteredGridData = this.gridData.filter(
            (item: any) => item.gridDisplay === this.translate.instant('PENDING_WITH_CUSTOMER')
          );
        },
      },
      {
        label: this.translate.instant('PENDING_WITH_CC'),
        command: (event) => {
          this.activeItem = event.item;
          this.filteredGridData = this.gridData.filter(
            (item: any) => item.gridDisplay === this.translate.instant('PENDING_WITH_CC')
          );
        },
      },
      {
        label: this.translate.instant('COMPLETED'),
        command: (event) => {
          this.activeItem = event.item;
          this.filteredGridData = this.gridData.filter(
            (item: any) => item.gridDisplay === this.translate.instant('COMPLETED')
          );
        },
      },
    ];
    this.fopStatus = {
      Initiated: this.translate.instant('PENDING_WITH_CUSTOMER'),
      Draft: this.translate.instant('PENDING_WITH_CUSTOMER'),
      Submitted: this.translate.instant('PENDING_WITH_CC'),
      Approved: this.translate.instant('COMPLETED'),
      Ready_To_Go: this.translate.instant('COMPLETED'),
      Correction_Required: this.translate.instant('PENDING_WITH_CUSTOMER'),
    }
    if (this.fopType) {
      this.GridFilterTabs.splice(0, 1);
    }
  }

  getFOPGridData() {
    this.spinner.show();
    this._fopHttpService
      .getFOPGridData(this.userId, this.isExternalUser)
      .subscribe((response: any) => {
        this.gridData = response.response;
        if (this.fopType) {
          if (this.fopType == 'SC')
            this.dialogHeader = this.translate.instant('CREATE_NEW_SHIP_TO_FOP');
          else if (this.fopType == 'CR')
            this.dialogHeader = this.translate.instant('CREATE_CUSTOMER_RENEWAL_FOP');
          else if (this.fopType == 'MP')
            this.dialogHeader = this.translate.instant('CREATE_NEW_MATERIAL_ADDITION_FOP');
          else this.dialogHeader = this.translate.instant('CREATE_FOP');
          this.gridData = this.gridData?.filter((fopData: any) =>
            String(fopData.fopTypeId).includes(this.fopType)
          );
        }
        this.gridData?.forEach((element: any) => {
          const status: string = element.status.replaceAll(' ', '_');
          element.gridDisplay = this.fopStatus[status];
        });
        if (this.fopType && this.gridData) {
          this.filteredGridData = JSON.parse(
            JSON.stringify(
              this.gridData?.filter(
                (item: any) => item.gridDisplay === this.translate.instant('PENDING_WITH_CUSTOMER')
              )
            )
          );
        } else if (this.gridData) {
          this.filteredGridData = JSON.parse(JSON.stringify(this.gridData));
        }
        this.spinner.hide();
      });
  }

  getCustomerDetails() {
    this._fopHttpService
      .getCustomerDetails(this.userId)
      .subscribe((response: any) => {
        const resp = response.response;
        this.customersDD = resp.customers;
        console.log(this.customersDD,"checking the dropdown object")
        this.users = resp.users;
        this.users.forEach((user: any) => {
          user.fullName = `${user.firstName} ${user.lastName}`;
        });
      });
  }
  selectedContractChange(){
    console.log(this.selectedyesnoDD)
      this._fopHttpService.getContractTypes().subscribe((response: any) => {
        if (!response['isError']) {
          console.log(response.response)
          this.typeOfContactDD = response.response;
        }
      });
    
  }
  customerChange() {
    this.usersDD = this.users.filter(
      (user: any) => user.customerId === this.selectedCustomer
    );
    let customerObj=this.customersDD.find((x:any)=>x.customerId==this.selectedCustomer);
    console.log(customerObj,"selected customer obj")
    this.isContractEnabled=customerObj.businessUnitName=="AP";     
  }

  showCreateFopDialog() {
    this.displayNewFopDialog = true;
    console.log(this.isContractEnabled,"this is the dialog enabled event")
    this.isContractEnabled=false;
    this.getCustomerDetails();
  }

  initiateNewFop(fopType: string) {
    this.newFopType = fopType;
    if (this.selectedGridRecord) {
      if (this.selectedGridRecord.status === 'Ready To Go') {
        sessionStorage.removeItem('shipSelectedReq');
        sessionStorage.removeItem('shipsavedFormData');
        // initiate FOP with selected NC Record
        this.selectedGridRecord.soldToId = '';
        this.newFop(fopType, this.selectedGridRecord);
      } else {
        this.toastr.warning(
          'You can initiate FOP only for a record which is "Completed"',
          'Info!'
        );
      }
    } else {
      // DIALOG to initiate FOP with SoldTo ID
      this.displayDialog = true;
    }
  }

  initiateSoldToFOP() {
    this.selectedGridRecord = {
      fopId: 0,
      soldToId: this.soldToId,
    };
    this.newFop(this.newFopType, this.selectedGridRecord);
  }

  newFop(fopType: string, data: any) {
    this.spinner.show();
    let requestObj = {
      fopId: parseInt(data.fopId),
      fopTypeName: fopType,
      soldToId: data.soldToId,
      createdBy: this.userId.toString(),
    };
    this._fopHttpService.initiateFOP(requestObj).subscribe((response: any) => {
      if (!response['isError']) {
        console.log(response);
      }
      this.selectedGridRecord = null;
      this.spinner.hide();
      this._fopHttpService.displayToastr(response);
      this.displayDialog = false;
      this.soldToId = '';
      this.getFOPGridData();
    });
  }

  closeDialog() {
    this.displayDialog = false;
    this.displayNewFopDialog = false;
    this.isContractEnabled=false;
    this.selectedCustomer=null;
    this.selectedUser=null;
    this.soldToId = '';
    this.selectedyesnoDD='';
    this.selectedtypeofContract=''
  }

  getHref(typeId: string) {
    if (typeId.includes('NC')) this.redirectTo('/new-customer');
    else if (typeId.includes('SC')) this.redirectTo('/ship-to-change');
    else if (typeId.includes('CR')) this.redirectTo('/customer-renewal');
    else if (typeId.includes('MP')) this.redirectTo('/material-addition');
    else this.redirectTo('/home');
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  capitalize(object: any) {
    let isArray = Array.isArray(object);
    for (let key in object) {
      let value = object[key];
      let newKey = key;
      if (!isArray) {
        // if it is an object
        delete object[key]; // firstly remove the key
        newKey = key.toUpperCase(); // secondly generate new key (capitalized)
      }
      let newValue = value;
      if (typeof value != 'object') {
        // if it is not an object (array or object in fact), stops here
        if (typeof value == 'string') {
          newValue = value.toUpperCase(); // if it is a string, capitalize it
        }
      } else {
        // if it is an object, recursively capitalize it
        newValue = this.capitalize(value);
      }
      object[newKey] = newValue;
    }
    return object;
  }

  exportExcel() {
    if (this.filteredGridData.length < 1) {
      this._fopHttpService.displayToastr({
        isError: true,
        response:  this.translate.instant(this.NO_GRID_DATA_AVAILABLE_TO_EXPORT_AS_EXCEL),
      });
      return;
    }
    let filteredGridDataCopy = this.filteredGridData?.map((x: any) => {
      return { ...x };
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        JSON.parse(JSON.stringify(this.capitalize(filteredGridDataCopy)))
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'FOP_gridData');
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data,fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,{ autoBom: false });
  }

  review(rowData: any, type: string) {
    if (type === 'edit') {
      sessionStorage.setItem('enduserFop', JSON.stringify(rowData));
    } else {
      sessionStorage.setItem('fop', JSON.stringify({ ...rowData, type: type }));
    }
    sessionStorage.setItem('fopId', rowData.fopId);
    sessionStorage.setItem('fopCountry', rowData.country);
    if (type === 'Ready To Go') {
      sessionStorage.setItem('fopReadyToGO', 'yes');
    } else {
      sessionStorage.setItem('fopReadyToGO', 'no');
    }
    this.getHref(rowData.fopTypeId);
  }

  initiateFOP() {
    this.spinner.show();
    let fopUsers: any[] = [];
    this.selectedUser.forEach((user: any) => {
      fopUsers.push({
        userId: user.userId,
        email: user.email,
      });
    });
    if(this.selectedyesnoDD && this.selectedtypeofContract==''){
      this.spinner.hide();
      this._fopHttpService.displayToastr({isError:true,response:this.translate.instant('Please select Contract')})
      return;
    }
    let requestObj = {
      customerId: this.selectedCustomer,
      businessUnitId: 0,
      createdBy: this.userId,
      fopUsers: fopUsers,
      isContractIncluded:this.selectedyesnoDD==1,
      contractId:this.selectedtypeofContract==""?0:this.selectedtypeofContract
    };
    this._fopHttpService
      .initiateNewFOP(requestObj)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          console.log(response);
        }
        this._fopHttpService.displayToastr(response);
        this.closeDialog();
        this.getFOPGridData();
      });
  }
}

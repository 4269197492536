// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-label {
    font-size: 16px;
    font-weight: 500;
}

.add-btn {
    color: white;
    background-color: #0096d6;
    margin: 10px;
    border-radius: 5px;
    float: right;
}

#multiSelect .p-multiselect {
    width: 100%;
    border: 1px solid black;
}

.form-group {
    display: flex;
}

.cust-form-label {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    display: block;
}

.cancel-btn {
    width: 150px;
    color: white;
    background-color: #4f4f4f;
    margin: 10px;
    border-radius: 5px;
}

.submit-btn {
    width: 150px;
    color: white;
    background-color: #0096d6;
    margin: 10px;
    border-radius: 5px;
}

small {
    font-weight: 600 !important;
}

.cust-form-group {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

#userReg .form-group {
    display: block;
}

.p-dropdown:not(.p-disabled).p-focus {
    border: 1px solid #4f4f4f;
}

.p-dropdown:not(.p-disabled):hover {
    border: 1px solid #4f4f4f !important;
    border-color: #4f4f4f !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/admin/sbu-module/sbu-module.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;AACpC","sourcesContent":[".header-label {\r\n    font-size: 16px;\r\n    font-weight: 500;\r\n}\r\n\r\n.add-btn {\r\n    color: white;\r\n    background-color: #0096d6;\r\n    margin: 10px;\r\n    border-radius: 5px;\r\n    float: right;\r\n}\r\n\r\n#multiSelect .p-multiselect {\r\n    width: 100%;\r\n    border: 1px solid black;\r\n}\r\n\r\n.form-group {\r\n    display: flex;\r\n}\r\n\r\n.cust-form-label {\r\n    font-size: 14px !important;\r\n    margin-bottom: 0px !important;\r\n    display: block;\r\n}\r\n\r\n.cancel-btn {\r\n    width: 150px;\r\n    color: white;\r\n    background-color: #4f4f4f;\r\n    margin: 10px;\r\n    border-radius: 5px;\r\n}\r\n\r\n.submit-btn {\r\n    width: 150px;\r\n    color: white;\r\n    background-color: #0096d6;\r\n    margin: 10px;\r\n    border-radius: 5px;\r\n}\r\n\r\nsmall {\r\n    font-weight: 600 !important;\r\n}\r\n\r\n.cust-form-group {\r\n    justify-content: flex-end;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n#userReg .form-group {\r\n    display: block;\r\n}\r\n\r\n.p-dropdown:not(.p-disabled).p-focus {\r\n    border: 1px solid #4f4f4f;\r\n}\r\n\r\n.p-dropdown:not(.p-disabled):hover {\r\n    border: 1px solid #4f4f4f !important;\r\n    border-color: #4f4f4f !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

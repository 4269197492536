// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#row {
    padding: 15px;
    background-color: #8f8f8f;
    display: flex;
}
.rowTitle {    
    color: white;
    padding: 5px;
    margin: 0;
    font-weight: 500;
}

.shippingForm .p-dropdown, .shippingForm .p-multiselect, .shippingForm .p-inputtext {
    width: 100% !important;
}

.shippingForm > #row .p-dropdown {
    width: 80%;
    border: none;
}

.p-dropdown-label-empty {
    width: 200px !important;
}
.p-dropdown-label {
    width: 200px !important;
}

#comments-input {
    padding: 10px;
}

button.p-element.p-button-raised {
    margin: 10px;
    width: -moz-fit-content;
    width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/shipping-requirements/shipping-requirements.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;AACjB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;AACtB","sourcesContent":["#row {\r\n    padding: 15px;\r\n    background-color: #8f8f8f;\r\n    display: flex;\r\n}\r\n.rowTitle {    \r\n    color: white;\r\n    padding: 5px;\r\n    margin: 0;\r\n    font-weight: 500;\r\n}\r\n\r\n.shippingForm .p-dropdown, .shippingForm .p-multiselect, .shippingForm .p-inputtext {\r\n    width: 100% !important;\r\n}\r\n\r\n.shippingForm > #row .p-dropdown {\r\n    width: 80%;\r\n    border: none;\r\n}\r\n\r\n.p-dropdown-label-empty {\r\n    width: 200px !important;\r\n}\r\n.p-dropdown-label {\r\n    width: 200px !important;\r\n}\r\n\r\n#comments-input {\r\n    padding: 10px;\r\n}\r\n\r\nbutton.p-element.p-button-raised {\r\n    margin: 10px;\r\n    width: fit-content;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cust-form-label {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  display: block;
}

.cust-form-group {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
}

#creditType {
  padding: 15px;
  background-color: #8f8f8f;
  display: flex;
  margin-left: 0.1%;
  width: 99.9%;
}

  #creditType .rowTitle {
    color: white;
    padding: 5px;
    margin: 0;
    font-weight: 600;
  }

  #creditType .p-dropdown {
    width: 60%;
  }

  #creditType > #row .p-dropdown {
    width: 80%;
    border: none;
  }

.creditForm .p-inputtext {
  width: 100% !important;
}

.cust-p-dialog {
  width: 50vw;
}

.mandatory_section .p-accordion-header-link {
  color: red !important;
  background: #cfcfcf !important;
}

@media (max-width: 768px) {
  /* CSS that should be displayed if width is equal to or less than 768px goes here */
  .cust-p-dialog {
    width: 90vw;
  }
}

@media (max-width: 400px) {
  /* CSS that should be displayed if width is equal to or less than 768px goes here */
  .cust-p-dialog {
    width: 98vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/credit-application/credit-application.component.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,iBAAiB;EACjB,YAAY;AACd;;EAEE;IACE,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;AAEF;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,mFAAmF;EACnF;IACE,WAAW;EACb;AACF;;AAEA;EACE,mFAAmF;EACnF;IACE,WAAW;EACb;AACF","sourcesContent":[".cust-form-label {\r\n  font-size: 14px !important;\r\n  margin-bottom: 0px !important;\r\n  display: block;\r\n}\r\n\r\n.cust-form-group {\r\n  justify-content: flex-end;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.form-group {\r\n  display: flex;\r\n}\r\n\r\n#creditType {\r\n  padding: 15px;\r\n  background-color: #8f8f8f;\r\n  display: flex;\r\n  margin-left: 0.1%;\r\n  width: 99.9%;\r\n}\r\n\r\n  #creditType .rowTitle {\r\n    color: white;\r\n    padding: 5px;\r\n    margin: 0;\r\n    font-weight: 600;\r\n  }\r\n\r\n  #creditType .p-dropdown {\r\n    width: 60%;\r\n  }\r\n\r\n  #creditType > #row .p-dropdown {\r\n    width: 80%;\r\n    border: none;\r\n  }\r\n\r\n.creditForm .p-inputtext {\r\n  width: 100% !important;\r\n}\r\n\r\n.cust-p-dialog {\r\n  width: 50vw;\r\n}\r\n\r\n.mandatory_section .p-accordion-header-link {\r\n  color: red !important;\r\n  background: #cfcfcf !important;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  /* CSS that should be displayed if width is equal to or less than 768px goes here */\r\n  .cust-p-dialog {\r\n    width: 90vw;\r\n  }\r\n}\r\n\r\n@media (max-width: 400px) {\r\n  /* CSS that should be displayed if width is equal to or less than 768px goes here */\r\n  .cust-p-dialog {\r\n    width: 98vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

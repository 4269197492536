import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-user-module',
  templateUrl: './user-module.component.html',
  styleUrls: ['./user-module.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserModuleComponent implements OnInit {
  cols: any;
  usersData: any;
  userAPIResp: any;
  filteredFields: any;
  filteredData: any;
  tempFilteredData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  businessUnits: any;
  countries: any;
  constructor(
    private service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getAllDivisionsAndCountries();
    this.getAllUsers();
  }

  getAllDivisionsAndCountries() {
    this.service.getAllDivisions().subscribe((response: any) => {
      if (!response['isError']) {
        this.businessUnits = response.response;
      }
    });
    this.service.getAllCountries().subscribe((response: any) => {
      if (!response['isError']) {
        this.countries = response.response;
      }
    });
  }

  getAllUsers() {
    this.spinner.show();
    this.service.getAllUsers().subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (!response['isError']) {
        this.userAPIResp = response.response;
        this.showForm = false;
        this.usersData = JSON.parse(JSON.stringify(this.userAPIResp.item1));
        this.updateUserBUs();
        this.updateUserCountries();
      } else {
        this.service.displayToastr(response);
      }
    });
  }
  updateUserBUs() {
    this.usersData.forEach((u: any) => {
      let fBusinesUnits = this.userAPIResp.item2.filter(
        (ua: any) => ua.userId === u.user_id
      );
      if (fBusinesUnits.length > 0) {
        let tBUnit = '';
        fBusinesUnits.forEach((fb: any) => {
          let fbUnit = this.businessUnits?.find(
            (bu: any) => bu.divisionId === fb.businessUnit_Id
          );
          if (tBUnit == '') {
            if (fbUnit?.['divisionName']) {
              tBUnit = fbUnit?.['divisionName'];
            }
          } else {
            tBUnit = tBUnit + ',' + fbUnit?.divisionName;
          }
        });
        u.businessUnits = tBUnit;
      } else {
        u.businessUnits = '';
      }
    });
  }
  updateUserCountries() {
    this.usersData.forEach((u: any) => {
      let fCountries = this.userAPIResp.item3.filter(
        (country: any) => country.userId === u.user_id
      );
      if (fCountries.length > 0) {
        let tcUnit = '';
        fCountries.forEach((fc: any) => {
          let fcUnit = this.countries?.find(
            (cu: any) => cu.country_id === fc.countryId
          );
          if (tcUnit == '') {
            if (fcUnit?.['country_Code']) {
              tcUnit = fcUnit?.['country_Code'];
            }
          } else {
            tcUnit = tcUnit + ',' + fcUnit?.country_Code;
          }
        });
        u.countries = tcUnit;
      }
    });
  }

  filterData(filterObj: any) {
    let { value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(
          (ff: any) => ff.fieldName !== filter
        );
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(
        (ff: any) => ff.fieldName == filter
      );
      if (foundField.length) {
        this.filteredFields.forEach((ff: any) => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        });
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value,
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.usersData;
      this.filteredFields.forEach((ff: any) => {
        temp = temp.filter((fd: any) =>
          fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase())
        );
      });
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.usersData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  onSelectRow(rowObject: any) {
    this.selectedRow = JSON.parse(JSON.stringify(rowObject));
    this.formType = 'Edit';
    this.showForm = true;
  }

  onDelete(rowObject: any) {
    this.service.DeleteUser(rowObject.user_id).subscribe((response: any) => {
      if (!response['isError']) {
        this.service.displayToastr(response);
        this.getAllUsers();
      } else {
        this.service.displayToastr(response);
      }
    });
  }

  addNewUser() {
    this.selectedRow = {
      user_id: 0,
      basf_user_id: '',
      first_name: '',
      last_name: '',
      email: '',
      is_admin: false,
    };
    this.formType = 'New';
    this.showForm = true;
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.getAllUsers();
    } else {
      this.showForm = false;
    }
  }
}

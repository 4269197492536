import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewCustomerComponent implements OnInit {
  @Input() renewalType: boolean = false;
  @Input() enableTaxTab: boolean = false;

  // Shipping Requirements component
  fopId: any = sessionStorage.getItem('fopId');
  selectedFop:any={};
  shipReqFormData: any;
  selectedShipRequest: string = '';
  items!: MenuItem[];
  activeItem: any;  
  isContractAvailable:boolean=false;
  disableForm: boolean = false;
  userId = parseInt(sessionStorage.getItem('userId') + '');
  isExternalUser: boolean =  JSON.parse(
     sessionStorage.getItem('isExternalUser') + ''
   );
  fopStatus: any = {
    Initiated:  'Pending With Customer',
    Draft: 'Pending With Customer',
    Submitted: 'Pending With CC',
    Approved: 'Completed',
    Ready_To_Go: 'Completed',
    Correction_Required: 'Pending With Customer',
  };   

  isDocumentationTabShow:boolean=false;
  constructor(
    private sharedService: SharedParameterService,
    private router: ActivatedRoute,
    private _fopHttpService: FopHttpService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService
  ) {
    const paramsFromRoute = this.router?.snapshot?.paramMap;
    if (paramsFromRoute?.get('fopId')) {
      this.fopId = paramsFromRoute.get('fopId');
      this.getFOPGridData();
      sessionStorage.setItem('fopId', this.fopId);
      sessionStorage.setItem('fopReadyToGO', 'no');
      this.disableForm = false;
    }
    sessionStorage.setItem('fopId', this.fopId);
    sessionStorage.removeItem('shipSelectedReq');
    sessionStorage.removeItem('shipsavedFormData');
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  ngOnInit(): void {
    this.getFOPGridData();    
  }

  initializeTabItems() {
    this.fopStatus = {
      Initiated: this.translate.instant('PENDING_WITH_CUSTOMER'),
      Draft: this.translate.instant('PENDING_WITH_CUSTOMER'),
      Submitted: this.translate.instant('PENDING_WITH_CC'),
      Approved: this.translate.instant('COMPLETED'),
      Ready_To_Go: this.translate.instant('COMPLETED'),
      Correction_Required: this.translate.instant('PENDING_WITH_CUSTOMER'),
    };
     this.items = [
      {
        label: this.renewalType
          ? this.translate.instant('CUSTOMER_RENEWAL_SETUP')
          : this.translate.instant('NEW_CUSTOMER_SETUP'),
        command: (NCevent) => {
          this.activeItem = NCevent.item;
        },
      },
      {
        label: this.translate.instant('CREDIT_APPLICATION'),
        command: (NCevent2) => {
          this.activeItem = NCevent2.item;
        },
      },
      {
        label: this.translate.instant('SHIPPING_REQUIREMENTS'),
        command: (NCevent3) => {
          this.activeItem = NCevent3.item;
        },
      },      
      {
        label: this.translate.instant('HISTORY_AND_COMMENTS'),
        command: (NCevent) => {
          this.activeItem = NCevent.item;
        },
      },
    ];
    let currentfop:any=sessionStorage.getItem('enduserFop')==null?sessionStorage.getItem('fop'):sessionStorage.getItem('enduserFop');
    
    if(currentfop)
    {
      currentfop=JSON.parse(currentfop);
      if(currentfop.isContractIncluded){        
        this.isContractAvailable=currentfop.isContractIncluded;
        this.items.splice(3,0,{
          label: this.translate.instant('CONTRACT'),
          command: (NCevent4) => {
            this.activeItem = NCevent4.item;
          }
        })        
      }
      if(currentfop.businessUnit=="AP"){
        this.isDocumentationTabShow=true;
        this.items.push({
          label: this.translate.instant('DOCUMENTATION'),
          command: (NCevent5) => {
            this.activeItem = NCevent5.item;
          },
        })
      }
    }      

    this.activeItem = this.items[0];
  }  

  value: number = 0;
  setActiveItem(value: any) {
    this.value = this.items.indexOf(this.activeItem);
    if (value == 'next') this.value = this.value + 1;
    else this.value = this.value - 1;
    this.activeItem = this.items[this.value];
  }

  closeEvent() {
    this.fopId = null;
    sessionStorage.removeItem('fopReadyToGO');
    sessionStorage.removeItem('coa');
    sessionStorage.removeItem('coaEmail');
    sessionStorage.removeItem('asn');
    sessionStorage.removeItem('asnEmail');
  }

  getFOPGridData() {
    this.spinner.show();
    this._fopHttpService
      .getFOPGridData(this.userId, this.isExternalUser)
      .subscribe((response: any) => {
        let gridData = response.response;
        let currentFop = {};
        gridData?.forEach((element: any) => {
          const status: string = element.status.replaceAll(' ', '_');
          element.gridDisplay = this.fopStatus[status];
          if (element.fopId.toString() === this.fopId) {
            currentFop = element;
            this.selectedFop=element;
          }
        });
        sessionStorage.setItem('enduserFop', JSON.stringify(currentFop));
        localStorage.setItem('countryfop', JSON.stringify(currentFop));
        this.initializeTabItems();
        setTimeout(() => {
        this.spinner.hide();
        }, 9000);
        
      });
  }
  getFopIdFromSession() {
    let basfCCfop: any = sessionStorage.getItem('fop');
    if (basfCCfop) basfCCfop = JSON.parse(basfCCfop);
    let enduserFop: any = sessionStorage.getItem('enduserFop');
    if (enduserFop) enduserFop = JSON.parse(enduserFop);
    if (basfCCfop?.fopId && basfCCfop.type !== 'edit') {
      if (basfCCfop.gridDisplay !== 'Pending With Customer') {
        this.disableForm = true;
      }
    }
    else if (enduserFop?.gridDisplay !== 'Pending With Customer') {      
      this.disableForm = true;
    }
    this.fopId = sessionStorage.getItem('fopId');
    sessionStorage.setItem('fopId', this.fopId);
  }
  ExportAll() {
    this.sharedService.ExportAll();
  }

  taxTab(value: any) {
    this.enableTaxTab = value;
  }
}

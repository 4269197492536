import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: [],
})
export class SamlComponent implements OnInit {
  userdataDB: any[] = [];
  returnUrl: any;
  returnUrlpath: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _service: FopHttpService
  ) {}
  usertoken: any;
  notAuthorized = false;
  isloading = true;
  userData: any = {
    email: '',
    id: 0,
    region: '',
    regionNumber: 0,
    role: '',
    userId: '',
    userName: '',
    notAuthorized: false,
  };
  usersData: any;
  usersData1: any;
  buUsersData: any;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['returnUrl'] != undefined) {
        sessionStorage.setItem('returnUrl', params['returnUrl']);
      }
      this.usertoken = params['Username'];
      if (this.usertoken == undefined || this.usertoken == '') {
        this.SAMLService();
      }
      else if (parseInt(params['userid'] + '') > 0){
        sessionStorage.setItem('loginuser', atob(params['Username']));

        this.returnUrl = sessionStorage.getItem('returnUrl');
        if (
          (this.returnUrl && this.returnUrl !== undefined) ||
          this.returnUrl !== ''
        ) {
          this.returnUrl = decodeURIComponent(this.returnUrl);
        } else {
          this.returnUrlpath = sessionStorage.getItem('returnUrlpath');
          this.router.navigate([this.returnUrlpath]);
        }
      }
      else{
        this.router.navigate(['/error']);
      }
    });
  }

  
  SAMLService() {
    this._service.getSAMLService().subscribe({
    next:(result: any)=>{
      if (!result['isError']) {
        window.location.href = result.strResponse.toString();
      } else {
        this._service.displayToastr(result);
      }
    },
    error:(error:any)=>{
      console.log('error ', error); 
    }});
  }


  
}

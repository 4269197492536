import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
    selector: 'app-country-module',
    templateUrl: './country-management.component.html',
    styleUrls:['./country-management.component.css']
})

export class CountryManagementComponent implements OnInit{

    cols: any;
    countryData: any;
    countryAPIResp: any;
    filteredFields: any;
    filteredData: any;
    tempFilteredData: any;
    selectedRow: any;
    formType: any;
    showForm = false;
    coutryId=0;
    countryName:any;
    countryCode:any;
    modifiedby:any;
    apiRequestObj: any = {
      "country_id": 0,
      "country_name": '',
      "country_Code": '',
      "modifedBy": 0
    };
    
    constructor(private service: FopHttpService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
      // country management component
    }
    ngOnInit() {
       this.getAllCountrys();
    }

    getAllCountrys(){
      this.spinner.show();
      this.service.getAllCountries().subscribe((response: any) => {
        if (!response['isError']) {
          this.countryAPIResp = response.response;
          this.showForm = false;
          this.countryData = JSON.parse(JSON.stringify(this.countryAPIResp));
        } else {
          this.service.displayToastr(response);
        }
      });
      this.spinner.hide();
    }

    onSelectRow(rowObject: any) {
      this.showForm=true;
      this.coutryId=rowObject.country_id;
      this.countryName=rowObject.country_name;
      this.countryCode=rowObject.country_Code;
    }

    deleteRow(rowObject: any) {
      this.coutryId=rowObject.country_id;
      this.countryName=rowObject.country_name;
      this.countryCode=rowObject.country_Code;
      this.modifiedby=rowObject.modifedBy
      this.service.deleteCountry(this.coutryId,this.modifiedby).subscribe((response:any)=>{
        if(!response['isError']) {
          this.apiRequestObj = response;
          this.service.displayToastr(this.apiRequestObj);
          this.getAllCountrys();
        } else {
          this.service.displayToastr(response);
        }
      })
    }

    submit() {
      this.spinner.show();
      this.apiRequestObj['country_id'] = this.coutryId;
      this.apiRequestObj['country_name'] = this.countryName;
      this.apiRequestObj['country_Code'] = this.countryCode;
      this.apiRequestObj['modifedBy'] = 1;
      this.service.postCreateorUpdateCountry(this.apiRequestObj).subscribe((response: any) => {
        if(!response['isError']) {
          this.apiRequestObj = response;
          this.countryData = JSON.parse(JSON.stringify(this.apiRequestObj));
          this.service.displayToastr(this.countryData);
          this.clear();
          this.getAllCountrys();
          
        } else {
          this.service.displayToastr(response);
        }
      })
      this.spinner.hide();
    }
  
    clear(){
      this.coutryId=0;
      this.countryName='';
      this.countryCode='';
      
    }
    cancel() {
      this.clear();
      this.showForm=false;
    }

    addNewCountry(){
      this.showForm=true;
    }

}
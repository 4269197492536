import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';
import {
  ShippingPayload,
  ShippingRequirementsHelper,
} from '../shipping.requirements.helper';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-rail-request',
  templateUrl: './rail-request.component.html',
  styleUrls: ['./rail-request.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class RailRequestComponent implements OnInit {
  InvalidEmailsListcofA = '';
  InvalidEmailsListcontactASN = '';
  @Input() submitFopTriggered!: Subject<string>;
  @Input() savedFormData: any;
  @Input() disableForm: any;
  @Output() closeFormEvent = new EventEmitter();
  @Output() submitFormEvent = new EventEmitter();
  @Output() getExistingShippingData = new EventEmitter();
  @Output() deleteForm = new EventEmitter();
  @Output() exportRrFormData = new EventEmitter();
  enduserFop: any = sessionStorage.getItem('enduserFop');
  coa: any = sessionStorage.getItem('coa');
  asn: any = sessionStorage.getItem('asn');
  coaEmail: any = sessionStorage.getItem('coaEmail');
  asnEmail: any = sessionStorage.getItem('asnEmail');
  submitted: boolean = false;
  railRequestDetails: UntypedFormGroup = {} as UntypedFormGroup;
  masterData: any;
  fopType: string | null = '';
  plantHolidayShdlDD: any = [];
  serviceRailRoadDD: any = [];
  yesOrNo = ['NA', 'Yes', 'No'];
  fopReadyToGO = sessionStorage.getItem('fopReadyToGO');
  shippingData: ShippingPayload = {} as ShippingPayload;
  @Input() companyCityStateOfShippingAddress: any[] = [];
  fopId = sessionStorage.getItem('fopId');
  @Input() materialNumberList: any = [];
  endUserBusiness: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private _fopHttpService: FopHttpService,
    private _sharedParameter: SharedParameterService,
    private _shippingHelper: ShippingRequirementsHelper,
    private translate: TranslateService
  ) {
    this.coa = this.yesOrNo[parseInt(this.coa ? this.coa : '0')];
    this.asn = this.yesOrNo[parseInt(this.asn ? this.asn : '0')];

    this.railRequestDetails = this.formBuilder.group({
      shippingID: 0,
      multiCompanyCityState: this.formBuilder.group({
        companyName: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
      }),
      companyname: ['', Validators.required],
      destinationcity: ['', Validators.required],
      destinationstate: ['', Validators.required],
      isReqFiledsFilled: [false],
      fop_Id: parseInt(sessionStorage.getItem('fopId') + ''),
      userId: [sessionStorage.getItem('userId')],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      faxNumber: [''],
      emailAddress: ['', [Validators.required, Validators.email]],
      receivingRailroad: [''],
      plantHolidayShdl: ['', Validators.required],
      customerProductCode: [''],
      normalOrderQty: [''],
      discharge_2x1: [0, Validators.required],
      discharge_3x1: [0, Validators.required],

      contactCOA: ['', Validators.required],
      contactASN: ['', Validators.required],
      otherRequirements: [''],
      bottomDischarge: [0, Validators.required],
      otherRailReq: [''],
      deliveryRailRoad: ['', Validators.required],
      railRoadSwichOrConstructivePlace: [''],
      noOfCarsSporttedLocation: [''],
      railConstraintBottleneck: [''],
      knownRailIssues: [''],
      plannedOutage: [''],
      alternateService: [''],
      serviceRailRoad: ['', Validators.required],
      railStationName: ['', Validators.required],
      materialDetails: this.formBuilder.array([
        this.formBuilder.group({
          materialNumber: [''],
          materialName: [''],
        }),
      ]),
      estimatedAnnualQuantity: ['', Validators.required],
    });
    this.fopType = localStorage.getItem('basicFopTypeId');
    this.masterData = this._fopHttpService.getSavedMasterData();
    if (this.masterData) {
      for (const element of this.masterData) {
        if (element['masterKey'] == 'PUBLIC HOLIDAYS')
          this.plantHolidayShdlDD = element['masterkeyDataList'];
        if (element['masterKey'] == 'SERVICE RAILROAD COMPETITIVE STATUS')
          this.serviceRailRoadDD = element['masterkeyDataList'];
      }
    }
    this.translate.stream('ALL_FOP').subscribe((resp) => {
      this.initializeTabItems();
    });
  }

  initializeTabItems() {
    this.yesOrNo = [
      'NA',
      this.translate.instant('YES'),
      this.translate.instant('NO'),
    ];
  }

  getValueOfSelectedMaterial(index: number) {
    let matName:any='';
    if (index >= 0) {
      let temparray: any = this.railRequestDetails?.get(
        'materialDetails'
      ) as UntypedFormArray;
      let matNumber = temparray?.value[index].materialNumber;

      if (matNumber) {
        let val: any = this.materialNumberList.find(
          (k: any) => k.materialNumber == matNumber
        );
        if (val?.materialName) {
         matName= val.materialName;
        }        
      }      
    }
    
    return matName;
  }

  ngOnInit(): void {
    this.initializeTabItems();
    if (this.enduserFop != undefined || this.enduserFop!= null) {
      this.endUserBusiness =JSON.parse(this.enduserFop).businessUnit;
      if(JSON.parse(this.enduserFop).businessUnit !='AP')

      this.railRequestDetails.get('normalOrderQty')?.setValidators(Validators.required);
    }
    if (this.fopType != '4') {
      this.railRequestDetails.get('companyname')?.clearValidators();
      this.railRequestDetails.get('destinationcity')?.clearValidators();
      this.railRequestDetails.get('destinationstate')?.clearValidators();
    } else {
      this.railRequestDetails
        .get('multiCompanyCityState')
        ?.get('companyName')
        ?.clearValidators();
      this.railRequestDetails
        .get('multiCompanyCityState')
        ?.get('city')
        ?.clearValidators();
      this.railRequestDetails
        .get('multiCompanyCityState')
        ?.get('state')
        ?.clearValidators();
    }

    this._shippingHelper
      .getShippingData()
      .subscribe((data: ShippingPayload) => {
        this.shippingData = data;
      });    
      console.log(this.savedFormData,"saved form data rail")

    if (this.savedFormData) {
      this.bindValuesForSavedFormData()
    }
    if (this.coa == 'Yes') {
      this.railRequestDetails.patchValue({
        contactCOA: this.coaEmail,
      });
    } else {
      this.railRequestDetails.get('contactCOA')?.clearValidators();
    }
    if (this.asn == 'Yes') {
      this.railRequestDetails.patchValue({
        contactASN: this.asnEmail,
      });
    } else {
      this.railRequestDetails.get('contactASN')?.clearValidators();
    }
    if (this.disableForm || this.fopReadyToGO === 'yes') {
      this.railRequestDetails.disable();
    }

    this.submitFopTriggered.subscribe((value) => {
      this.saveFormData();
    });
  }
  onSelectCompany(event: any) {
    let id = event.value;
    console.log(id);
    console.log(this.railRequestDetails.value);

    let cmp = this.railRequestDetails?.get('multiCompanyCityState');

    cmp?.get('city')?.patchValue(id);
    cmp?.get('state')?.patchValue(id);
  }

  bindValuesForSavedFormData(){
    this.railRequestDetails.patchValue(this.savedFormData);
      this.submitted = true;
      if(this.savedFormData.contactASN){
        let savedAsnEmail=this.savedFormData.contactASN;
        this.asnEmail=(savedAsnEmail!=undefined && savedAsnEmail!='')?savedAsnEmail:this.asnEmail;
      }
      if(this.savedFormData.contactCOA){
        let savedCOAEmail=this.savedFormData.contactCOA;
        this.coaEmail=(savedCOAEmail!=undefined && savedCOAEmail!='')?savedCOAEmail:this.coaEmail;
      }
      
      if (this.savedFormData.multiCompanyCityState)
        this.BindCompanyCityStateToForm();
      this.InitializeMaterialFormArray();
  }

  BindCompanyCityStateToForm() {
    let dd = this.savedFormData.multiCompanyCityState as any[];

    if (dd && dd.length > 0) {
      let ids = dd.map((k) => k.addressId);
      this.railRequestDetails?.patchValue({
        multiCompanyCityState: {
          companyName: ids,
          city: ids,
          state: ids,
        },
      });
    }
  }

  get f() {
    return this.railRequestDetails.controls as any;
  }
  MapCompanyCityState() {
    let cmp = this.railRequestDetails.value.multiCompanyCityState;
    let ret: any[] = [];
    let tempRailId = cmp?.companyName as any[];
    if (tempRailId && tempRailId.length > 0) {
      tempRailId.forEach((k: string) => {
        let temprailm = this.companyCityStateOfShippingAddress.find(
          (p) => p.addressId == k
        );

        if (temprailm) {
          ret.push({
            addressId: temprailm.addressId,
            companyName: temprailm.companyName,
            city: temprailm.city,
            state: temprailm.state,
          });
        }
      });
    }

    return ret;
  }

  deleteItem() {
    let shippingrailID: number = this.savedFormData.shippingID;
    if (shippingrailID == 0) {
      this.deleteForm.emit();
    } else {
      this._fopHttpService
        .deleteShiipingData(this.fopId, shippingrailID)
        .subscribe((response: any) => {
          let IsError: boolean = response.IsError;
          if (!IsError) {
            this.deleteForm.emit();
          }
        });
    }
  }
  saveFormData() {
    if (this.fopReadyToGO === 'yes') {
      return;
    }
    this.submitted = true;
    this.railRequestDetails.value.isReqFiledsFilled =
      !this.railRequestDetails.invalid;
    this.railRequestDetails.value.multiCompanyCityState =
      this.MapCompanyCityState();

    const findExisting = this.shippingData.railRequests?.find(
      (o: any) =>
        o.shippingID == this.railRequestDetails.value.shippingID &&
        this.railRequestDetails.value.shippingID != 0
    );
    let newCustomerObj: any;
    if (findExisting) {
      newCustomerObj = this.railRequestDetails.value;
      newCustomerObj.materialDetails =
        this.mapMaterialListiWithFormGroup(newCustomerObj);

      newCustomerObj.isReqFiledsFilled = !this.railRequestDetails.invalid;

      this.shippingData.railRequests[
        this.shippingData.railRequests.indexOf(findExisting)
      ] = newCustomerObj;
    } else {
      newCustomerObj = this.railRequestDetails.value;
      newCustomerObj.materialDetails =
        this.mapMaterialListiWithFormGroup(newCustomerObj);

      newCustomerObj.isReqFiledsFilled = !this.railRequestDetails.invalid;

      if (this.shippingData.railRequests) {
        this.shippingData.railRequests.push(newCustomerObj);
      } else {
        this.shippingData.railRequests = [newCustomerObj];
      }
    }
    this.exportRrFormData.emit(newCustomerObj);
    this._shippingHelper.sendShippingData(this.shippingData);
  }

  addMaterial(): void {
    const railmaterialForm = this.formBuilder.group({
      materialNumber: [''],
      materialName: [''],
    });
    this.GetMaterialFormArray.push(railmaterialForm);
  }
  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }
  removeMaterial(index: number): void {
    this.GetMaterialFormArray.removeAt(index);
    let railitm = this.savedFormData.materialDetails as any[];
    railitm.splice(index, 1);
    this.savedFormData.materialDetails = railitm;
  }
  InitializeMaterialFormArray() {
    let frm = this.railRequestDetails?.get('materialDetails') as UntypedFormArray;

    if (this.savedFormData) {
      let matdet = this.savedFormData.materialDetails as any[];
      if (matdet != undefined && matdet.length > 0) {
        frm.clear();
        for (const iterator of matdet) {
          let ctrl = this.formBuilder.group({
            materialNumber: [iterator.materialNumber],
            materialName: [iterator.materialName],
          });
          frm.push(ctrl);
        }
      }
    }
  }
  mapMaterialListiWithFormGroup(newCustomerObj: any): any {
    let val = newCustomerObj.materialDetails as any[];
    let newrailval: any[] = [];
    if (this.savedFormData) {
      let matdet = this.savedFormData.materialDetails as any[];

      for (let i = 0; i < val.length; i++) {
        let mat =
          matdet != undefined && matdet.length > 0 ? matdet[i] : undefined;
        if (mat) {
          newrailval.push({
            id: mat.id,
            materialNumber: val[i].materialNumber,
            materialName: val[i].materialName,
          });
        } else
          newrailval.push({
            id: 0,
            materialNumber: val[i].materialNumber,
            materialName: val[i].materialName,
          });
      }
    }
    return newrailval;
  }
  get GetMaterialFormArray() {
    return this.railRequestDetails?.get('materialDetails') as UntypedFormArray;
  }
  validateEmailMultipleID(getPaperworkField: any) {
    if (getPaperworkField == this.railRequestDetails.value.contactCOA) {
      let emailsSeparator = this.railRequestDetails.value.contactCOA.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);
      this.InvalidEmailsListcofA = '' + invalidEmails;
    }
    if (getPaperworkField == this.railRequestDetails.value.contactASN) {
      let emailsSeparator = this.railRequestDetails.value.contactASN.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListcontactASN = '' + invalidEmails;
    }
  }

  validateEmailmultiple(emailsSeparator: any) {
    let re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return re.test(emailsSeparator);
  }
  coaAsnChange(value: any, type: string) {
    sessionStorage.setItem(type, value);
  }
  returnEmail(emailsSeparator: any, invalidEmails: any) {
    for (const iterator of emailsSeparator) {
      if (!this.validateEmailmultiple(iterator.trim())) {
        invalidEmails.push(iterator.trim());
      }
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-new-customer-fop',
  templateUrl: './new-customer-fop.component.html',
  styleUrls: ['./new-customer-fop.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewCustomerFopComponent implements OnInit {
  bu = 1;
  userId = parseInt(sessionStorage.getItem('userId') + '');
  customers: any = [];
  duplicateCustomer: boolean = false;
  newCustomerFopForm: UntypedFormGroup;
  submitted: boolean = false;
  sbuOrgDD: any = [];
  sbuDD: any = [];
  buDD: any = [];
  countryDD: any = [];
  editForm: any;
  closeButton: boolean = false;
  region = [{ name: 'NA', code: 1 }];
  isEdit: boolean = false;
  emailValid: any = [];
  masterData: any = {};
  salesOrgDropDown: any = [];
  distributionChannelDropDown: any = [];
  FIRST_NAME_AND_EMAIL_FIELDS_ARE_REQUIRED = 'FIRST_NAME_AND_EMAIL_FIELDS_ARE_REQUIRED';
  MARKED_FIELD_VALUES_CANNONT_BE_EMPTY = 'MARKED_FIELD_VALUES_CANNONT_BE_EMPTY';

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private _fopHttpService: FopHttpService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    this.newCustomerFopForm = this.fb.group({
      customer_id: 0,
      customer_name: [''],
      customer_business_unit: [0, Validators.required],
      customer_region: [1, Validators.required],
      customer_country: [0, Validators.required],
      created_by: [this.userId.toString()],
      sbu: ['', Validators.required],
      distribution_channel: ['', Validators.required],
      salesOrg: ['', Validators.required],
      sold_to: [''],
      users: this.fb.array([]),
    });

    this.masterData = this._fopHttpService.getSavedMasterData();
    for (const iterator of this.masterData) {
      const element = iterator;
      if (element['masterKey'] == 'DISTRIBUTION CHANNEL')
        this.distributionChannelDropDown = element['masterkeyDataList'];
      if (element['masterKey'] == 'SALESORG')
        this.salesOrgDropDown = element['masterkeyDataList'];
    }
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  initializeTabItems() {
    this.translate.instant(this.FIRST_NAME_AND_EMAIL_FIELDS_ARE_REQUIRED);
    this.translate.instant(this.MARKED_FIELD_VALUES_CANNONT_BE_EMPTY);
  }

  ngOnInit(): void {
    this.getAdminDropDownData(null);
    this.getAllCustomersByUserId();    
  }

  isNewOrExistingCustomer(){
    if (history.state?.data) {
      this.isEdit = true;
      this.editForm = history.state.data.rowData;
      this._fopHttpService
        .getCustomer(this.editForm.customerId)
        .subscribe((response: any) => {
          let sbus: any[] = [];
          response.response.sbu.forEach((element: any) => {
            sbus.push(element);
          });

          this.newCustomerFopForm.patchValue({
            customer_id: response.response.customerId,
            customer_name: response.response.customerName,
            customer_region: response.response.regionId,
            customer_country: response.response.countryId,
            sold_to: response.response.sold_to,
            customer_business_unit: response.response.businessUnitId,
            distribution_channel: response.response.distributionChannel,
            salesOrg: response.response.salesOrg,
            users: [],
            sbu: sbus,
          });
          console.log(this.newCustomerFopForm.value,"check form value",this.sbuDD,"check sbu dd values")
          response.response.users.forEach((user: any) => {
            this.users.push(this.fb.group(user));
          });
          if (this.sbuOrgDD && this.sbuOrgDD.length > 0) {
            sbus.forEach((s: any) => {
              this.sbuOrgDD.forEach((sbuOrg: any) => {
                if (s.sbu_id == sbuOrg.sbu_id) {
                  sbuOrg.customer_sbu_mapping_id = s.customer_sbu_mapping_id;
                }
              });
            });
            this.onBuChange({ value: response.response.businessUnitId });
          } else {
            this.getAdminDropDownData({
              value: response.response.businessUnitId,
            });
          }
        });
    } else {
      this.addUser();
    }
  }

  salesOrgChange(salesOrg: any) {
    this.newCustomerFopForm.patchValue({
      customer_country: salesOrg,
    });
  }

  getAllCustomersByUserId() {
    this._fopHttpService
      .getAllCustomersByUserId(this.userId)
      .subscribe((response: any) => {
        this.customers = response.response;
      });
  }

  customerNameValidation() {
    const filteredCustomers = this.customers.filter(
      (customer: any) => customer.customerName === this.f['customer_name'].value
    );
    if (filteredCustomers.length > 0) {
      this.duplicateCustomer = true;
    } else {
      this.duplicateCustomer = false;
    }
  }

  navigateToAdmin() { this.router.navigate(['/admin']); }

  getAdminDropDownData(event: any) {
    this._fopHttpService.getAdminDropDownData().subscribe((response: any) => {
      this.buDD = response.businessUnitData;
      this.sbuOrgDD = response.subData;
      this.countryDD = response.countryData;

      if (event) {
        this.onBuChange(event);
      }
      this.isNewOrExistingCustomer();
    });
  }

  onBuChange(event: any) {
    this.sbuDD = this.sbuOrgDD?.filter((sbu: any) => sbu.bu_id === event.value);
  }

  get f() {
    return this.newCustomerFopForm.controls;
  }

  get users(): UntypedFormArray {
    return this.newCustomerFopForm.get('users') as UntypedFormArray;
  }

  removeUser(i: number) {
    this.users.removeAt(i);
  }

  newUser(): UntypedFormGroup {
    return this.fb.group({
      customer_user_id: 0,
      first_name: '',
      last_name: '',
      email: ['', Validators.email],
      refId: 0,
    });
  }

  addUser() {
    this.users.push(this.newUser());
  }

  validateEmail(i: any) {
    const er: any = this.newCustomerFopForm.controls['users'];
    const emailErrors = er['controls'][i]['controls']['email']['errors'];
    if (emailErrors) {
      this.emailValid[i] = emailErrors['email'];
    } else {
      this.emailValid[i] = false;
    }
  }

  onSubmit() {
    this.submitted = true;
    for (const user of this.users.value) {
      if (user.first_name.length < 1 || user.email.length < 1) {
        this._fopHttpService.displayToastr({
          isError: true,
          response: this.translate.instant(this.FIRST_NAME_AND_EMAIL_FIELDS_ARE_REQUIRED),
        });
        return;
      }
    }
    if (this.newCustomerFopForm.invalid) {
      this._fopHttpService.displayToastr({
        isError: true,
        response: this.translate.instant(this.MARKED_FIELD_VALUES_CANNONT_BE_EMPTY),
      });
      return;
    }
    if (this.duplicateCustomer) return;
    this.spinner.show();
    let requestObj = this.newCustomerFopForm.value;
    const sbu = this.newCustomerFopForm.value.sbu;
    let sbuArray: any = [];
    this.sbuDD.forEach((sbuDDdata: any) => {
      let exists = false;
      let selectedSbu: any;
      sbu.forEach((sbudata: any) => {
        if (sbudata.sbu_id == sbuDDdata.sbu_id) {
          exists = true;
          selectedSbu = sbudata;
        }
      });
      if (exists) {
        sbuArray.push({
          customer_sbu_id: selectedSbu.sbu_id,
          customer_sbu_mapping_id: selectedSbu.customer_sbu_mapping_id,
        });
      }
    });
    requestObj.sbu = sbuArray;
    this._fopHttpService
      .postCreateOrUpdateCustomerData(requestObj)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
        if (!response['isError']) {
          this.router.navigate(['/admin']);
        }
        this._fopHttpService.displayToastr(response);
        this.closeButton = true;
      });
  }
}

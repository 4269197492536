import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { AdminComponent } from './components/admin/admin.component';
import { NewCustomerFopComponent } from './components/admin/new-customer-fop/new-customer-fop.component';
import { TestLoginComponent } from './components/admin/test-login/test-login.component';
import { CustomerRenewalComponent } from './components/customer-renewal/customer-renewal.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MaterialAdditionComponent } from './components/material-addition/material-addition.component';
import { NewCustomerComponent } from './components/new-customer/new-customer.component';
import { SamlComponent } from './components/saml/saml.component';
import { ShipToChangeComponent } from './components/ship-to-change/ship-to-change.component';
import { BasfSignatoryDetailsComponent } from './components/admin/contract-management-module/basf-signatory-details/basf-signatory-details.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'saml', pathMatch: 'full', component: SamlComponent },
  {
    path: 'home',
    pathMatch: 'prefix',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'home' },
  },
  {
    path: 'new-customer',
    pathMatch: 'full',
    component: NewCustomerComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'new-customer' },
  },
  {
    path: 'ship-to-change',
    pathMatch: 'full',
    component: ShipToChangeComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'ship-to-change' },
  },
  {
    path: 'customer-renewal',
    pathMatch: 'full',
    component: CustomerRenewalComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'customer-renewal' },
  },
  {
    path: 'material-addition',
    pathMatch: 'full',
    component: MaterialAdditionComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'material-addition' },
  },
  {
    path: 'new-customer/:fopId',
    component: NewCustomerComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'new-customer' },
  },
  {
    path: 'ship-to-change/:fopId',
    component: ShipToChangeComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'ship-to-change' },
  },
  {
    path: 'customer-renewal/:fopId',
    component: CustomerRenewalComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'customer-renewal' },
  },
  {
    path: 'material-addition/:fopId',
    component: MaterialAdditionComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'material-addition' },
  },
  {
    path: 'admin',
    pathMatch: 'prefix',
    component: AdminComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'admin' },
  },
  {
    path: 'admin/new-customer',
    pathMatch: 'prefix',
    component: NewCustomerFopComponent,
    canActivate: [AuthenticationGuard],
    data: { expectedurl: 'admin/new-customer' },
  },
  //{ path: 'test-login', pathMatch: 'full', component: TestLoginComponent, data: { expectedurl: 'test-login' } },
  {
    path: 'login-test',
    pathMatch: 'full',
    component: TestLoginComponent,
    data: { expectedurl: 'test-login' },
  },
  {
    path: 'sign',
    pathMatch: 'full',
    component: BasfSignatoryDetailsComponent,
    data: { expectedurl: 'sign' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

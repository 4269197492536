// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#row {
    padding: 10px;
    background-color: #8f8f8f;
    display: flex;
}
.rowTitle {    
    color: white;
    padding: 0px;
    margin: 0;
    font-weight: 600;
}

.p-datatable {    
    border-bottom: 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/history-and-comments/history-and-comments.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;AACjB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["#row {\r\n    padding: 10px;\r\n    background-color: #8f8f8f;\r\n    display: flex;\r\n}\r\n.rowTitle {    \r\n    color: white;\r\n    padding: 0px;\r\n    margin: 0;\r\n    font-weight: 600;\r\n}\r\n\r\n.p-datatable {    \r\n    border-bottom: 1px solid;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

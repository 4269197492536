import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit,Output,ViewChild} from '@angular/core';
// import {} from 'text-signature'


import SignaturePad from 'signature_pad';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-basf-signatory-details',
  templateUrl: './basf-signatory-details.component.html',
  styleUrls: ['./basf-signatory-details.component.css']
})
export class BasfSignatoryDetailsComponent implements OnInit {

  signPad: any;
  @ViewChild('signPadCanvas', {static: false}) signaturePadElement:any;
  signImage:any=null; 
  textSignImage:any=null;

 @Input() custForm:boolean=false;
 @Output() ondetailsSubmit=new EventEmitter();
 adminName:string='';
 adminTitle:string='';
 agreementDate= formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
 adminSignaturePadImage:any=null;
 adminTextSignatureImage:any=null;

 Textsignature=require("text-signature");

 optionsParameter = {
  width: 450,
  height: 100,
  paddingX: 5,
  paddingY: 50,
  canvasTargetDom: ".js-canvasTargetDom",
  font:  ["40px", "'Homemade Apple'"],
  color: "black",
  textString: "",
  customFont: { 
    name: "'Homemade Apple'", 
    url: "../../../../../assets/fonts/customFont.css"  
  }
 
};
  constructor(private service:FopHttpService) { }

  ngOnInit(): void {
    console.log(this.custForm,"checking the custform value on init")
  } 
  onInputchange(){
    if(this.optionsParameter.textString.length>0){
    let textSignature = new this.Textsignature(this.optionsParameter);    
    textSignature.generateImage(this.optionsParameter);
    this.textSignImage=textSignature.getImageData();
    this.adminTextSignatureImage=this.textSignImage.replace(`data:image/png;base64,`, '');
    }
    else{
      this.textSignImage=null;
    }
  }

  onCancel(){
    this.resetControls()
  }

  validation(){
    if(this.adminName=='')
    {
      this.service.displayToastr({response:'Please enter Name',authorize:true,isError:true});
      return false;
    }
    if(this.adminTitle=='')
    {
      this.service.displayToastr({response:'Please enter Title',authorize:true,isError:true});

      return false;
    }
    if((this.adminSignaturePadImage==null || this.adminSignaturePadImage.length==0) && (this.adminTextSignatureImage==null|| this.adminTextSignatureImage.length==0))
    {
      this.service.displayToastr({response:'Please give a signature',authorize:true,isError:true});
      return false;
    }
    return true;
  }

  onSubmit(){
    if(!this.validation()){      
      return;
    }
    let payload={      
      adminName:this.adminName,
      adminTitle:this.adminTitle,
      agreementDate:this.agreementDate,
      adminSignatureImage: this.adminSignaturePadImage??this.adminTextSignatureImage,
    }
    console.log(payload,"this is child component")
    this.ondetailsSubmit.emit(payload);
    this.resetControls();
  }

  resetControls(){
    this.adminName="";
    this.adminTitle="";
    this.signImage=null;
    this.textSignImage=null;
    this.adminSignaturePadImage=null;
    this.adminTextSignatureImage=null;
    this.optionsParameter.textString="";
    this.clearSignPad();
  }

          


/*It's work in devices*/
startSignPadDrawing(event: Event) {
  console.log(event);
}

  ngAfterViewInit() {
    this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signPad.clear();
  }
  
  
  /*Undo last step from the signature*/
  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); // remove the last step
      this.signPad.fromData(data);
    }
  }
  movedFinger(event: Event) {
    console.log(event);
  }
  /*Clean whole the signature*/
  clearSignPad() {
    this.signPad.clear();
    this.signImage=null;
  }
 

  close() {
    this.signPad.clear();
    this.signImage = null;    
  }

   /*Here you can save the signature as a Image*/
   saveSignPad() {
    const base64ImageData = this.signPad.toDataURL();
    this.signImage = base64ImageData;
    //Here you can save your signature image using your API call.
    this.adminSignaturePadImage = this.signImage.replace(`data:image/png;base64,`, '');
    
  }



}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FopHttpService } from '../services/fop-http.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  isAdmin: boolean | undefined;
  isExternalUser: boolean | undefined;
  constructor(private service: FopHttpService, private _fopService: FopHttpService, private router: Router) {
  }

  ngOnInit() {
    this.service.getAdminValue().subscribe(response => {
      this.isAdmin = (response === "true");
    });
    if(sessionStorage.getItem('isAdmin')) {
      this._fopService.sendAdminValue(String(sessionStorage.getItem('isAdmin')).toLowerCase());
    }
    this.service.getIsExternalUser().subscribe(response => {
      this.isExternalUser = (response === "true");
    });
    if(sessionStorage.getItem('isExternalUser')) {
      this._fopService.sendIsExternalUser(String(sessionStorage.getItem('isExternalUser')).toLowerCase());
    }
  }
  clearFopIdInSession () {
    sessionStorage.removeItem('fopId');
  }
  navigateTo() {
    this.router.navigate(['/home']);
  }
}

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-bu-module',
  templateUrl: './bu-module.component.html',
  styleUrls: ['./bu-module.component.css'],
})
export class BuModuleComponent implements OnInit {
  userId = sessionStorage.getItem('userId') + '';
  cols: any;
  divisionData: any;
  divisionAPIResp: any;
  filteredFields: any;
  filteredData: any;
  tempFilteredData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  divisionName: any;
  divisionId: any = 0;
  divisionDescription: any;
  modifiedby: any;
  apiRequestObj: any = {
    divisionId: 0,
    divisionName: '',
    createdBy: '0',
    divisionDescription: '',
  };

  constructor(
    private service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.getAllDivisions();
  }

  getAllDivisions() {
    this.spinner.show();
    this.service.getAllDivisions().subscribe((response: any) => {
      if (!response['isError']) {
        this.divisionAPIResp = response.response;
        this.showForm = false;
        this.divisionData = JSON.parse(JSON.stringify(this.divisionAPIResp));
      } else {
        this.service.displayToastr(response);
      }
    });
    this.spinner.hide();
  }

  onSelectRow(rowObject: any) {
    this.showForm = true;
    this.divisionName = rowObject.divisionName;
    this.divisionDescription = rowObject.divisionDescription;
    this.divisionId = rowObject.divisionId;
  }

  deleteRow(rowObject: any) {
    this.divisionName = rowObject.divisionName;
    this.divisionDescription = rowObject.divisionDescription;
    this.divisionId = rowObject.divisionId;
    this.modifiedby = rowObject.createdBy;

    this.service
      .deleteDivision(this.divisionId, this.modifiedby)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;

          this.service.displayToastr(this.apiRequestObj);
          this.getAllDivisions();
        } else {
          this.service.displayToastr(response);
        }
      });
  }

  submit() {
    this.spinner.show();

    let apiRequestObj: any = {};
    apiRequestObj['divisionId'] = this.divisionId;
    apiRequestObj['divisionName'] = this.divisionName;
    apiRequestObj['createdBy'] = this.userId; // Need to update from authrozations
    apiRequestObj['divisionDescription'] = this.divisionDescription;

    this.service
      .postCreateorUpdateDivision(apiRequestObj)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.divisionData = JSON.parse(JSON.stringify(this.apiRequestObj));
          this.service.displayToastr(this.divisionData);
          this.clear();
          this.getAllDivisions();
        } else {
          this.service.displayToastr(response);
        }
      });
    this.spinner.hide();
  }

  clear() {
    this.divisionId = 0;
    this.divisionName = '';
    this.divisionDescription = '';
  }
  cancel() {
    this.clear();
    this.showForm = false;
  }

  addNewDivision() {
    this.showForm = true;
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cust-form-label {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    display: block;
}

.cust-form-group {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.form-group {
    display: flex;
    margin-top: 5px;
}

button {
    width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ship-to-change/tax-exempt-certificate/tax-exempt-certificate.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".cust-form-label {\r\n    font-size: 14px !important;\r\n    margin-bottom: 0px !important;\r\n    display: block;\r\n}\r\n\r\n.cust-form-group {\r\n    justify-content: flex-end;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.form-group {\r\n    display: flex;\r\n    margin-top: 5px;\r\n}\r\n\r\nbutton {\r\n    width: 90%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #8f8f8f;
    color: white;
    border-color: #e6e6e6;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-weight: 600;
    font-size: x-large;
    padding: 15px;
    color: #8f8f8f;
}

.p-tabmenu-nav {
    border-bottom: 1px solid #8f8f8f;
}`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/new-customer.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {\r\n    background: #8f8f8f;\r\n    color: white;\r\n    border-color: #e6e6e6;\r\n}\r\n\r\n.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {\r\n    font-weight: 600;\r\n    font-size: x-large;\r\n    padding: 15px;\r\n    color: #8f8f8f;\r\n}\r\n\r\n.p-tabmenu-nav {\r\n    border-bottom: 1px solid #8f8f8f;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

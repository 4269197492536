// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright {
    text-align: right;
    line-height: 40px;
    color: #fff;
    font-size: 12px;
    width:100vw;
}

.footer {
    position: fixed; 
    bottom: 0;
    background-color: #4f4f4f;
    z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,SAAS;IACT,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".copyright {\r\n    text-align: right;\r\n    line-height: 40px;\r\n    color: #fff;\r\n    font-size: 12px;\r\n    width:100vw;\r\n}\r\n\r\n.footer {\r\n    position: fixed; \r\n    bottom: 0;\r\n    background-color: #4f4f4f;\r\n    z-index: 999;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

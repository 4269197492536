import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Customer Setup Portal';
  languages = ['en', 'fr', 'es'];  
  currentLanguage: string;

  constructor(private translate: TranslateService) {
    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang('en');
    this.currentLanguage = sessionStorage.getItem('language') ?? 'en';
    this.translate.use(this.currentLanguage);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';

@Component({
  selector: 'app-material-addition',
  templateUrl: './material-addition.component.html',
  styleUrls: ['./material-addition.component.css'],
})
export class MaterialAdditionComponent implements OnInit {
  items!: MenuItem[];
  activeItem: any;
  disableForm: boolean = false;
  userId = parseInt(sessionStorage.getItem('userId') + '');
  isExternalUser: boolean = JSON.parse(
    sessionStorage.getItem('isExternalUser') + ''
  );
  fopStatus: any = {
    Initiated: 'Pending With Customer',
    Draft: 'Pending With Customer',
    Submitted: 'Pending With CC',
    Approved: 'Completed',
    Ready_To_Go: 'Completed',
    Correction_Required: 'Pending With Customer',
  };

  // Shipping form data
  fopId: any = sessionStorage.getItem('fopId');
  shipReqFormData: any;
  selectedShipRequest: string = '';
  isDocumentationTabShow:boolean=false;
  constructor(
    private _fopHttpService: FopHttpService,
    private sharedService: SharedParameterService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    const paramsFromRoute = this.router.snapshot?.paramMap;
    if (paramsFromRoute?.get('fopId')) {
      this.fopId = paramsFromRoute.get('fopId');
      this.getFOPGridData();
      sessionStorage.setItem('fopId', this.fopId);
    }
    sessionStorage.setItem('fopId', this.fopId);
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initializeTabItems();
    // Get previously saved shipping request form data
    if (this.fopId) {
      this.getSavedShippingData();
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 9000);
  }

  initializeTabItems() {
    this.fopStatus = {
      Initiated: this.translate.instant('PENDING_WITH_CUSTOMER'),
      Draft: this.translate.instant('PENDING_WITH_CUSTOMER'),
      Submitted: this.translate.instant('PENDING_WITH_CC'),
      Approved: this.translate.instant('COMPLETED'),
      Ready_To_Go: this.translate.instant('COMPLETED'),
      Correction_Required: this.translate.instant('PENDING_WITH_CUSTOMER'),
    };
    this.items = [
      {
        label: this.translate.instant('SHIPPING_REQUIREMENTS'),
        command: (MAevent) => {
          this.activeItem = MAevent.item;
        },
      },
      {
        label: this.translate.instant('HISTORY_AND_COMMENTS'),
        command: (MAevent) => {
          this.activeItem = MAevent.item;
        },
      },
    ];

    let currentfopMA:any=sessionStorage.getItem('enduserFop')==null?sessionStorage.getItem('fop'):sessionStorage.getItem('enduserFop');
    
    if(currentfopMA)
    {
      currentfopMA=JSON.parse(currentfopMA);      
      if(currentfopMA.businessUnit=="AP"){
        this.isDocumentationTabShow=true;
        this.items.push({
          label: this.translate.instant('DOCUMENTATION'),
          command: (MAevent) => {
            this.activeItem = MAevent.item;
          },
        })
      }
    }
    this.activeItem = this.items[0];
  }

  getFopIdFromSession() {
    let basfCCfop: any = sessionStorage.getItem('fop');
    if (basfCCfop) basfCCfop = JSON.parse(basfCCfop);
    let enduserFop: any = sessionStorage.getItem('enduserFop');
    if (enduserFop) enduserFop = JSON.parse(enduserFop);
    if (basfCCfop?.fopId && basfCCfop.type !== 'edit') {
      if (basfCCfop.gridDisplay !== this.translate.instant('PENDING_WITH_CUSTOMER')) {
        this.disableForm = true;
      }
    }
    else if(enduserFop?.gridDisplay !== this.translate.instant('PENDING_WITH_CUSTOMER')){
      this.disableForm = true;
    }
    this.fopId = sessionStorage.getItem('fopId');
    sessionStorage.setItem('fopId', this.fopId);
  }

  getSavedShippingData() {
    this._fopHttpService
      .getShippingReqData(this.fopId)
      .subscribe((response: any) => {
        const resp = response.response;
        switch (resp.shipingType) {
          case 'BULKTRUCK':
            this.shipReqFormData = resp.bulTrukRequest;
            this.selectedShipRequest = 'BTR';
            break;
          case 'PKGTRUCK':
            this.selectedShipRequest = 'PTR';
            this.shipReqFormData = resp.packageTruckRequest;
            break;
          case 'RAIL':
            this.selectedShipRequest = 'RR';
            this.shipReqFormData = resp.railRequest;
            break;
          default:
            break;
        }
      });
  }

  getFOPGridData() {
    this._fopHttpService
      .getFOPGridData(this.userId, this.isExternalUser)
      .subscribe((response: any) => {
        let gridData = response.response;
        let currentFop = {};
        gridData?.forEach((element: any) => {
          const status: string = element.status.replaceAll(' ', '_');
          element.gridDisplay = this.fopStatus[status];
          if (element.fopId.toString() === this.fopId) {
            currentFop = element;
          }
        });
        sessionStorage.setItem('enduserFop', JSON.stringify(currentFop));
      });
  }

  ExportAll(){
    this.sharedService.ExportAll();
  }

  closeEvent() {
    this.fopId = null;
  }
}

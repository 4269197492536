import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {

  @Input() isAdmin:boolean=false;
  userId = sessionStorage.getItem('userId') + '';  
  documentsData: any;
  documentsAPIResp: any;
  showForm = false;
  isEditMode:boolean=false;

  documentId:any;
  documentationType:any;
  documentFileName:any;
  documentFileAttachmentContent:any;
  documentFileExtension:any;
  documentUploaded:boolean=false;
  fileDownloaded:boolean=false;
  modifiedby: any;
  
  constructor(private service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,private translate:TranslateService) { }

  ngOnInit(): void {
    this.getAllDocuments();
  }

  getAllDocuments() {
    this.spinner.show();
    this.service.getAllDocuments().subscribe((response: any) => {
      if (!response['isError']) {
        this.documentsAPIResp = response.response;
        this.showForm = false;
        this.documentsData = JSON.parse(JSON.stringify(this.documentsAPIResp));
      } else {
        this.service.displayToastr(response);
      }
      this.spinner.hide();
    });
    
  }

  onSelectRow(rowObject: any) {
    this.showForm = true;
    this.isEditMode = true;
    this.documentId = rowObject.documentId;
    this.documentationType=rowObject.documentationType;
    this.documentFileName = rowObject.documentFileName;
    this.documentFileAttachmentContent = rowObject.documentFileAttachmentContent;
    this.documentFileExtension = rowObject.documentFileExtension;
  }

  deleteRow(rowObject: any) {
    this.spinner.show()
    this.documentId = rowObject.documentId;
    this.documentationType=rowObject.documentationType;
    this.documentFileName = rowObject.documentFileName;
    this.documentFileAttachmentContent = rowObject.documentFileAttachmentContent;
    this.documentFileExtension = rowObject.documentFileExtension;
    this.modifiedby = rowObject.createdBy;

    this.service
      .deleteDocument(this.documentId, this.modifiedby)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.toastr.success(this.translate.instant(response.response),this.translate.instant("SUCCESS!"));
          this.clear()
          this.getAllDocuments();
        } else {
          this.service.displayToastr(response);
        }
        this.spinner.hide();
      });
  }

  submit() {
    this.spinner.show();
    if(!this.validateForm()){
      this.spinner.hide()
      return;
    }
    let apiRequestObj: any = {
      'documentId':this.documentId,
      'documentationType':this.documentationType,
      'documentFileName':this.documentFileName,
      'documentFileAttachmentContent':this.documentFileAttachmentContent,
      'documentFileExtension':this.documentFileExtension,
      'createdBy':this.userId,// Need to update from authrozations
    };   

    this.service
      .postCreateorUpdateDocument(apiRequestObj)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.service.displayToastr(response);
          this.clear();
          this.spinner.hide();
          this.getAllDocuments();
        } else {
          this.spinner.hide();
          this.service.displayToastr(response);
        }
      });    
  }

  validateForm(){
    let isValid:boolean=true;
    if(this.documentationType=='' || this.documentationType==null || this.documentationType==undefined){
      this.toastr.warning(this.translate.instant('PLEASE_ENTER_DOCUMENTATION_TYPE'))
      isValid=false;
    }
    else if(this.documentFileName==''|| this.documentFileName==null || this.documentFileName==undefined){
      this.toastr.warning(this.translate.instant('PLEASE_UPLOAD_AN_ATTACHMENT'))
      isValid=false;
    }
    console.log(isValid,this.documentationType,this.documentFileName,"Validate form")
    return isValid;
  }

  clear() {
    this.documentId = 0;
    this.documentationType = '';
    this.documentFileName = '';
    this.documentFileAttachmentContent = '';
    this.documentFileExtension = '';
    this.isEditMode= false;
  }

  cancel() {
    this.clear();
    this.showForm = false;
    this.isEditMode= false;
  }

  addNewDocument() {
    this.showForm = true;
  }


  OnDocumentUpload(event:any) {
    this.spinner.show();
    console.log(event)
    let fileData = event.target.files[0];
    const element = event.target.files[0];
    console.log(element,"doc element type")
    if (element.size > 3145728) {
      this.OnRemoveAttachment();
      this.toastr.warning(this.translate.instant("FILE_LIMIT_EXCEEDED"));
      this.spinner.hide();
      return;
    }
    else if(element.type!='application/pdf' && element.type!='application/vnd.openxmlformats-officedocument.wordprocessingml.document' && element.type!='application/msword'){
      this.OnRemoveAttachment()
      this.toastr.warning(this.translate.instant("DOCUMENT_TYPE_NOT_ALLOWED"));
      this.spinner.hide();
      return;
    }
     else {
      this.documentFileName = fileData.name;
      this.documentFileExtension = fileData.type;
      this.documentFileAttachmentContent = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.documentFileAttachmentContent = fileReader.result;
        this.documentFileAttachmentContent = this.documentFileAttachmentContent.replace(`data:${this.documentFileExtension};base64,`, '');
        this.documentUploaded = true;
      }
      fileReader.readAsDataURL(fileData);
    }
    this.spinner.hide();
  }

  OnRemoveAttachment() {
    this.documentFileAttachmentContent = "";
    this.documentFileName = "";
    this.documentFileExtension = "";
    this.documentUploaded = false;
  }

  downLoadAttachment(rowData:any) {
    this.fileDownloaded = false; 
    if(rowData=='formAttachment'){
      this.downLoadCAttachment(this.documentFileName, this.documentFileExtension,this.documentFileAttachmentContent);

    }
    else{
      this.downLoadCAttachment(rowData.documentFileName, rowData.documentFileExtension,rowData.documentFileAttachmentContent,);
    }
    this.fileDownloaded = true;
  } 

downLoadCAttachment(fName:any, fType:any, fAttachment:any) {
  const downloadLink = document.createElement("a");
  const fileName = fName;

  downloadLink.href = `data:${fType};base64,${fAttachment}`;
  downloadLink.download = fileName;
  downloadLink.click();
}



}

import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
  CanActivateFn,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthguardServiceService } from './services/authguard-service.service';
import { FopHttpService } from './services/fop-http.service';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard{
  loginvalue: any;
  expireDate: any;
  authService: any;
  childactive = false;
  routparams: any;
  constructor(
    private _fopService: FopHttpService,
    private toastr: ToastrService,
    private Authguardservice: AuthguardServiceService,
    public router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const expectedurl = route.data['expectedurl'];
    this.routparams = route.queryParams;

    if (this.routparams?.loginUser) {
      if (!this.routparams.userid || this.routparams.userid.length < 1) {
        this.toastr.error(
          'User not registered for this application',
          'Login Error'
        );
        this.router.navigate(['/'], {
          queryParams: { returnUrl: expectedurl },
        });
        return false;
      } else {
        this.updateSession();
      }
    }

    if (!this.Authguardservice.gettoken()) {
      this.redirectToSaml(state, expectedurl);
      return false;
    } else {
      return this.redirectToRedirectUrl(expectedurl);
    }
  }
 

  updateSession() {
    sessionStorage.setItem('loginuseremail', atob(this.routparams['Email']));
    sessionStorage.setItem('jwtToken', this.routparams['Email']);
    sessionStorage.setItem('UserToken', JSON.stringify(this.routparams));
    sessionStorage.setItem(
      'isAdmin',
      String(this.routparams['IsAdmin']).toLowerCase()
    );
    sessionStorage.setItem(
      'isContractManager',
      String(this.routparams['IsContractManager']).toLowerCase()
    );
    sessionStorage.setItem('userId', this.routparams['userid']);
    sessionStorage.setItem(
      'isExternalUser',
      String(this.routparams['IsExternalUser']).toLowerCase()
    );

    this._fopService.sendAdminValue(
      String(this.routparams['IsAdmin']).toLowerCase()
    );
    this._fopService.sendIsExternalUser(
      String(this.routparams['IsExternalUser']).toLowerCase()
    );
  }

  redirectToSaml(state: any, expectedurl: any) {
    if (this.routparams != null) {
      sessionStorage.setItem(
        'returnUrlparams',
        JSON.stringify(this.routparams)
      );
      if (JSON.stringify(this.routparams).includes('RequestId')) {
        sessionStorage.setItem('paramsExist', 'true');
      }
      sessionStorage.setItem('returnUrlpath', state.url);
    } else {
      sessionStorage.setItem('paramsExist', 'false');
    }
    sessionStorage.setItem('returnUrl', expectedurl);
    this.router.navigate(['/saml'], {
      queryParams: { returnUrl: expectedurl },
    });
  }

  redirectToRedirectUrl(expectedurl: any): boolean {
    if (expectedurl?.includes('admin')) {
      if (JSON.parse(sessionStorage.getItem('isAdmin') + '')) return true;
      else {
        this.toastr.error('Unauthorized');
        this.router.navigate(['/home']);
        return false;
      }
    } else {
      let returnUrlpath = sessionStorage.getItem('returnUrlpath');
      if (returnUrlpath && returnUrlpath.length > 2) {
        sessionStorage.removeItem('returnUrlpath');

        this.router.navigate([returnUrlpath]);
      }
      return true;
    }
  }
  
}
export const AuthenticationGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree>
| Promise<boolean | UrlTree>
| boolean
| UrlTree => {
  return inject(AuthGuard).canActivate(next, state);
}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FopHttpService {
  isAdmin = new BehaviorSubject(sessionStorage.getItem('IsAdmin'));
  isExternalUser = new BehaviorSubject(
    sessionStorage.getItem('isExternalUser')
  );
  SUCCESS = "SUCCESS!";
  ERROR = "ERROR!";

  constructor(private _http: HttpClient, private toastr: ToastrService, private translate: TranslateService) {
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  initializeTabItems() {
    this.translate.instant(this.SUCCESS);
    this.translate.instant(this.ERROR);
  }

  displayToastr(response: any) {
    if (response.isError || !response.authorize) {
      if (response['errorMessage']) {
        this.toastr.error(response['errorMessage'], this.translate.instant(this.ERROR));
      } else {
        this.toastr.error(response['response'], this.translate.instant(this.ERROR));
      }
    }
    if (
      response['authorize'] &&
      !response.isError &&
      response['responseMessage'] &&
      response['responseMessage'] != ''
    ) {
      this.toastr.success(response['responseMessage'], this.translate.instant(this.SUCCESS));
    } else if (
      response['authorize'] &&
      !response.isError &&
      response['response'] != ''
    ) {
      this.toastr.success(response['response'], this.translate.instant(this.SUCCESS));
    }
  }

  getSavedMasterData(): any[] {
    let masterData: any[] = [];
    let masterDataString = sessionStorage.getItem('masterData');
    if (masterDataString) {
      masterData = JSON.parse(masterDataString);
    } else {
      this.getMasterData().subscribe((response: any) => {
        if (response.response && response.response.length > 0) {
          masterData = response.response;
          sessionStorage.setItem('masterData', JSON.stringify(masterData));
        }
      });
    }
    return masterData;
  }

  sendAdminValue(value: any) {
    this.isAdmin.next(value);
  }
  getAdminValue(): Observable<any> {
    return this.isAdmin.asObservable();
  }

  sendIsExternalUser(value: any) {
    this.isExternalUser.next(value);
  }
  getIsExternalUser(): Observable<any> {
    return this.isExternalUser.asObservable();
  }

  getSAMLService(): Observable<any> {
    return this._http
      .get(`${environment.getSamlURL}`)
      .pipe(map((response) => response));
  }

  getUserByEmail(email: any) {
    return this._http
      .get(`${environment.getUserByEmail}?emailId=${email}`)
      .pipe(map((response) => response));
  }

  getShippingReqData(fopId: any) {
    return this._http
      .get(`${environment.getShippingReqData}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  deleteShiipingData(fopId: any,shippingId:any){
    return this._http
      .delete(`${environment.deleteShippingData}?fopId=${fopId}&shippingId=${shippingId}`)
      .pipe(map((response) => response));
  }
  saveShippingData(fopId: any, requestData: any) {
    return this._http
      .post(`${environment.saveShippingData}/${fopId}`, requestData)
      .pipe(map((response) => response));
  }

  getCompnaystatecityFromShippingAddres(fopId: any){
    return this._http
      .get(`${environment.getCompnaystatecityFromShippingAddres}?fopId=${fopId}`)
      .pipe(map((response) => response));

  }
  // sendBulkTruckRequest(requestData: any) {
  //   return this._http
  //     .post(`${environment.sendBulkTruckData}`, requestData)
  //     .pipe(map((response) => response));
  // }

  getMasterData() {
    return this._http
      .get(`${environment.getMasterData}`)
      .pipe(map((response) => response));
  }

  getCustomerAccountSpecialistData(fopId: any) {
    return this._http
      .get(`${environment.getCustomerAccountSpecialistData}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  getAdminDropDownData() {
    return this._http
      .get(`${environment.getAdminDropDownData}`)
      .pipe(map((response) => response));
  }

  getAllCustomersByUserId(userId: any) {
    return this._http
      .get(`${environment.getAllCustomersByUserId}?userId=${userId}`)
      .pipe(map((response) => response));
  }

  getCustomer(customerId: number) {
    return this._http
      .get(`${environment.getCustomer}?customerId=${customerId}`)
      .pipe(map((response) => response));
  }
  postCreateOrUpdateCustomerData(customerData: any) {
    return this._http
      .post(`${environment.postCreateOrUpdateCustomerData}`, customerData)
      .pipe(map((response) => response));
  }

  saveNewCustomer(requestData: any, country: any) {
    const headers = new HttpHeaders().set('FOPCountry', country);
    return this._http
      .post(`${environment.saveCustomerSetup}`, requestData, {
        headers: headers,
      })
      .pipe(map((response) => response));
  }

  getCustomerInfobySoldToId(fopId: any) {
    return this._http
      .get(`${environment.getCustomerInfobySoldToId}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  // sendPackageTruckRequest(requestData: any) {
  //   return this._http
  //     .post(`${environment.sendPackageTruckData}`, requestData)
  //     .pipe(map((response) => response));
  // }

  // sendRailRequest(requestData: any) {
  //   return this._http
  //     .post(`${environment.sendRailRequestData}`, requestData)
  //     .pipe(map((response) => response));
  // }

  getFopHistory(fopId: any) {
    return this._http
      .get(`${environment.getFopHistory}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  getFOPGridData(userId: any, isExternal: boolean) {
    return this._http
      .get(
        `${environment.getFOPGridData}?userId=${userId}&isExternal=${isExternal}`
      )
      .pipe(map((response) => response));
  }

  getCustomerDetails(userId: any) {
    return this._http
      .get(`${environment.getCustomerDetails}?userId=${userId}`)
      .pipe(map((response) => response));
  }

  initiateNewFOP(requestData: any) {
    return this._http
      .post(`${environment.initiateNewFOP}`, requestData)
      .pipe(map((response) => response));
  }

  initiateFOP(requestData: any) {
    return this._http
      .post(`${environment.initiateFOP}`, requestData)
      .pipe(map((response) => response));
  }

  saveCreditSetup(requestData: any) {
    return this._http
      .post(`${environment.saveCreditSetup}`, requestData)
      .pipe(map((response) => response));
  }

  saveCreditUploadSetup(requestData: any) {
    return this._http
      .post(`${environment.saveCreditUploadSetup}`, requestData)
      .pipe(map((response) => response));
  }

  reviewFopRequest(requestData: any) {
    return this._http
      .post(`${environment.reviewFopRequest}`, requestData)
      .pipe(map((response) => response));
  }

  submitFOP(fopId: any, fopTypeId: any, userId: any) {
    return this._http
      .post(
        `${environment.submitFOP}?fopId=${fopId}&fopTypeId=${fopTypeId}&createdBy=${userId}`,
        null
      )
      .pipe(map((response) => response));
  }

  readyToGo(requestObj: any) {
    return this._http
      .post(`${environment.readyToGo}`, requestObj)
      .pipe(map((response) => response));
  }

  getNewCustomerSetupData(fopId: any) {
    return this._http
      .get(`${environment.getNewCustomerSetupData}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  getCreditApplicationData(fopId: any) {
    return this._http
      .get(`${environment.getCreditApplicationData}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  GetFOPInitiationInfo(fopId: any) {
    return this._http
      .get(`${environment.GetFOPInitiationInfo}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  saveCreditCanadaSetup(requestData: any) {
    return this._http
      .post(`${environment.saveCACreditSetup}`, requestData)
      .pipe(map((response) => response));
  }

  GetFopComments(fopId: any, parentId: any, userId: any) {
    return this._http
      .get(
        `${environment.GetFopComments}?fopId=${fopId}&parentId=${parentId}&UserId=${userId}`
      )
      .pipe(map((response) => response));
  }

  CreateComments(requestObj: any) {
    return this._http
      .post(`${environment.CreateComments}`, requestObj)
      .pipe(map((response) => response));
  }

  getCACreditApplicationData(fopId: any) {
    return this._http
      .get(`${environment.getCACreditApplicationData}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  postCreateorUpdateUser(requestObj: any) {
    return this._http
      .post(`${environment.postCreateorUpdateUser}`, requestObj)
      .pipe(map((response) => response));
  }

  getAllUsers() {
    return this._http
      .get(`${environment.getAllUser}`)
      .pipe(map((response) => response));
  }

  getUserDetailsBasedOnId(userId: any) {
    return this._http
      .get(`${environment.getUserDetailsBasedOnId}?userId=${userId}`)
      .pipe(map((response) => response));
  }

  saveTaxExceptCertificate(requestObj: any) {
    return this._http
      .post(`${environment.SaveTaxExceptCertificate}`, requestObj)
      .pipe(map((response) => response));
  }

  getTaxExemptionCertificate(fopId: any) {
    return this._http
      .get(`${environment.GetTaxExemptionCertificate}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  getTaxExemptionTemplateCertificate() {
    return this._http
      .get(`${environment.GetTaxExemptionTemplateCertificate}`)
      .pipe(map((response) => response));
  }

  getCATaxInfoCertificateTemplates(certificateType: any) {
    return this._http
      .get(
        `${environment.getCATaxInfoCertificateTemplates}?certificateType=${certificateType}`
      )
      .pipe(map((response) => response));
  }

  getGridAllMasterData(masterkey: any) {
    return this._http
      .get(`${environment.getAllMasterData}?masterKey=${masterkey}`)
      .pipe(map((response) => response));
  }

  getMasterDropDownData() {
    return this._http
      .get(`${environment.getMasterDropDown}`)
      .pipe(map((response) => response));
  }

  getAllCountries() {
    return this._http
      .get(`${environment.getAllCountries}`)
      .pipe(map((response) => response));
  }

  postCreateorUpdateCountry(requestObj: any) {
    return this._http
      .post(`${environment.postCreateorUpdateCountry}`, requestObj)
      .pipe(map((response) => response));
  }

  deleteCountry(countryId: any, modifiedBy: any) {
    return this._http
      .delete(
        `${environment.deleteCountry}?countryId=${countryId}&modifiedBy=${modifiedBy}`
      )
      .pipe(map((response) => response));
  }

  getAllDivisions() {
    return this._http
      .get(`${environment.getAllDivisions}`)
      .pipe(map((response) => response));
  }
  getContractTypes() {
    return this._http
      .get(`${environment.GetContractTypes}`)
      .pipe(map((response) => response));
  }

  postCreateorUpdateDivision(requestObj: any) {
    return this._http
      .post(`${environment.postCreateorUpdateDivision}`, requestObj)
      .pipe(map((response) => response));
  }

  deleteDivision(divisionId: any, modifiedBy: any) {
    return this._http
      .delete(
        `${environment.deleteDivision}?divisionId=${divisionId}&modifiedBy=${modifiedBy}`
      )
      .pipe(map((response) => response));
  }

  getAllSbuData() {
    return this._http
      .get(`${environment.GetAllSbuData}`)
      .pipe(map((response) => response));
  }

  postCreateOrUpdateSbu(requestObj: any) {
    return this._http
      .post(`${environment.CreateOrUpdateSbu}`, requestObj)
      .pipe(map((response) => response));
  }

  deleteSBU(sbuId: any, modifiedBy: any) {
    return this._http
      .delete(
        `${environment.DeleteSBU}?sbuId=${sbuId}&modifiedBy=${modifiedBy}`
      )
      .pipe(map((response) => response));
  }

  postCreateorUpdateMaster(requestObj: any) {
    return this._http
      .post(`${environment.postCreateOrUpdateMaster}`, requestObj)
      .pipe(map((response) => response));
  }

  deleteMaster(masterId: any, modifiedBy: any) {
    return this._http
      .delete(
        `${environment.deleteMasterData}?masterId=${masterId}&modifiedBy=${modifiedBy}`
      )
      .pipe(map((response) => response));
  }

  createOrUpdateCustomerAccount(requestObj: any) {
    return this._http
      .post(`${environment.postCreateOrUpdateCustomerAccount}`, requestObj)
      .pipe(map((response) => response));
  }

  DeleteCustomerAccount(customerId: any, modifiedBy: any) {
    return this._http
      .delete(
        `${environment.deleteCustomerAccount}?customerAccount=${customerId}&modifiedBy=${modifiedBy}`
      )
      .pipe(map((response) => response));
  }

  getAllCustomerAccount() {
    return this._http
      .get(`${environment.getAllCustomerAccount}`)
      .pipe(map((response) => response));
  }

  getCustomerAccountById(id: any) {
    return this._http
      .get(`${environment.getCustomerAccountById}?accountId=${id}`)
      .pipe(map((response) => response));
  }

  createOrUpdateUser(requestParams: any) {
    return this._http
      .post(`${environment.postCreateorUpdateUser}`, requestParams)
      .pipe(map((resp) => resp));
  }

  DeleteUser(userId: any) {
    return this._http
      .delete(`${environment.deleteUser}?userId=${userId}`)
      .pipe(map((response) => response));
  }

  ExportAll(fopId: any, fop: any, fopTypeId: any, country: any) {
    return this._http
      .get(
        `${environment.exportAll}?fopId=${fopId}&fop=${fop}&fopTypeId=${fopTypeId}&country=${country}`
      )
      .pipe(map((response) => response));
  }
  GetMaterils(fopId: any) {
    return this._http
      .get(`${environment.GetMaterils}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }

  updateContractDocumentMaster(formdata:any){
    return this._http
          .post(`${environment.updateContractDocumentMaster}`,formdata)
          .pipe(map((response) => response))
  }

  updateDocwithSignatorydetails(formdata:any){
    return this._http
          .post(`${environment.updateDocwithSignatorydetails}`,formdata)
          .pipe(map((response) => response))
  }
  getContractDocumentMaster(typeofContract:number){
    return this._http
          .get(`${environment.getContractDocumentMaster}?contractId=${typeofContract}`)
          .pipe(map((response) => response))
  }


  getCustomerContractDocument(fopId:any){
    return this._http
      .get(`${environment.getCustomerContractDocument}?fopId=${fopId}`)
      .pipe(map((response) => response));
  }
  updateCustomerContractDocument(formdata:any){
    return this._http
      .post(`${environment.updateCustomerContractDocument}`,formdata)
      .pipe(map((response) => response));
  }


  getAllDocuments() {
    return this._http
      .get(`${environment.getAllDocuments}`)
      .pipe(map((response) => response));
  }

  postCreateorUpdateDocument(requestObj: any) {
    return this._http
      .post(`${environment.postCreateorUpdateDocument}`, requestObj)
      .pipe(map((response) => response));
  }

  deleteDocument(documentId: any, modifiedBy: any) {
    return this._http
      .delete(
        `${environment.deleteDocument}?documentId=${documentId}&modifiedBy=${modifiedBy}`
      )
      .pipe(map((response) => response));
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-history-and-comments',
  templateUrl: './history-and-comments.component.html',
  styleUrls: ['./history-and-comments.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HistoryAndCommentsComponent implements OnInit {
  fopId: any;
  history: any;

  constructor(private _fopHttpService: FopHttpService) {
    let basfCCfop: any = sessionStorage.getItem('fop');
    if (basfCCfop) {
      basfCCfop = JSON.parse(basfCCfop);
    }
    let enduserFop: any = sessionStorage.getItem('enduserFop');
    if (enduserFop) {
      enduserFop = JSON.parse(enduserFop);
    }
    if (basfCCfop?.fopId) {
      this.fopId = basfCCfop?.fopId;
    } else {
      this.fopId = enduserFop?.fopId;
    }
  }

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory() {
    this._fopHttpService
      .getFopHistory(this.fopId)
      .subscribe((response: any) => {
        if (response.isError) {
          this._fopHttpService.displayToastr(response);
          return;
        }
        this.history = response.response;
        this.history.forEach((state: any) => {
          state.formattedDate = new Date(state.date)
            .toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
            .replace(/ /g, '-');
        });
        this.history.sort((a: any, b: any) => b.date.localeCompare(a.date));
      });
  }
}

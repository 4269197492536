import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-masterdata-module',
  templateUrl: './master-data-management.component.html',
  styleUrls: ['./master-data-management.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MasterDataComponent implements OnInit {
  cols: any;
  masterData: any;
  dropDownData: any[] = [];
  masterAPIResp: any;
  filteredFields: any;
  filteredData: any;
  tempFilteredData: any;
  selectedRow: any;
  formType: any;
  showForm: boolean = false;
  showGrid = false;
  masterKey: any = '';
  masterValue: any;
  masterId = 0;
  modifiedby: any;
  apiRequestObj: any = {
    master_id: 0,
    master_key: '',
    master_key_value: '',
    modifedBy: 0,
  };

  constructor(
    private service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.getMasterDropDownData();
    this.getAllMasterData();
  }
  cancel() {
    this.clear();
    this.masterKey = '';
    this.showForm = false;
    this.getAllMasterData();
  }
  clear() {
    this.masterId = 0;
    this.masterKey = '';
    this.masterValue = '';
    this.showForm = false;
  }

  submit() {
    if (
      this.masterKey == '' ||
      this.masterValue == '' ||
      this.masterKey == undefined ||
      this.masterValue == undefined
    ) {
      this.toastr.warning('Please enter the mandatory fields');
      return;
    }
    this.spinner.show();
    this.apiRequestObj['master_id'] = this.masterId;
    this.apiRequestObj['master_key'] = this.masterKey;
    this.apiRequestObj['master_key_value'] = this.masterValue;
    this.apiRequestObj['modifiedby'] = 1;
    this.service
      .postCreateorUpdateMaster(this.apiRequestObj)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.masterData = JSON.parse(JSON.stringify(this.apiRequestObj));
          this.service.displayToastr(this.masterData);
          this.clear();
          this.getAllMasterData();
        } else {
          this.service.displayToastr(response);
        }
      });
    this.spinner.hide();
  }

  addNewMasterData() {
    this.showForm = true;
  }

  onEdit(rowObject: any) {
    this.showForm = true;
    this.masterId = rowObject.master_id;
    this.masterKey = rowObject.master_key;
    this.masterValue = rowObject.master_key_value;
  }

  onDelete(rowObject: any) {
    this.masterId = rowObject.master_id;
    this.modifiedby = rowObject.modifiedBy;
    this.service
      .deleteMaster(this.masterId, this.modifiedby)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.service.displayToastr(this.apiRequestObj);
          this.getAllMasterData();
        } else {
          this.service.displayToastr(response);
        }
      });
  }

  getMasterDropDownData() {
    this.spinner.show();
    this.service.getMasterDropDownData().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.masterAPIResp = response.response;
        this.masterAPIResp.forEach((key: any) => {
          this.dropDownData.push({ key: key, value: key });
        });
      } else {
        this.service.displayToastr(response);
      }
    });
    this.spinner.hide();
  }

  getAllMasterData() {
    this.spinner.show();
    this.service
      .getGridAllMasterData(this.masterKey)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.masterAPIResp = response.response;
          this.masterData = JSON.parse(JSON.stringify(this.masterAPIResp));
        } else {
          this.service.displayToastr(response);
        }
      });
    this.spinner.hide();
  }
}

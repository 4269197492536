// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-accordion-header-link {
    padding: 20px;
    background-color: #dbdbdb;
}

.form-group {
    display: flex;
    margin-top: 10px;
}

.col-md-6 > input {
    width: 60%;
    height: 35px;  
}

.p-dropdown {
    width: 60% !important;
}

.mandatory_section .p-accordion-header-link {
  color: red !important;
  background: #cfcfcf !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/shipping-requirements/package-truck-request/package-truck-request.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;EACE,qBAAqB;EACrB,8BAA8B;AAChC","sourcesContent":[".p-accordion-header-link {\r\n    padding: 20px;\r\n    background-color: #dbdbdb;\r\n}\r\n\r\n.form-group {\r\n    display: flex;\r\n    margin-top: 10px;\r\n}\r\n\r\n.col-md-6 > input {\r\n    width: 60%;\r\n    height: 35px;  \r\n}\r\n\r\n.p-dropdown {\r\n    width: 60% !important;\r\n}\r\n\r\n.mandatory_section .p-accordion-header-link {\r\n  color: red !important;\r\n  background: #cfcfcf !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

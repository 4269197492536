import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-renewal',
  templateUrl: './customer-renewal.component.html',
  styleUrls: ['./customer-renewal.component.css'],
})
export class CustomerRenewalComponent {
  // Customer Renewal Component
}

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FopHttpService } from 'src/app/services/fop-http.service';



@Component({
  selector: 'app-contract-management-module',
  templateUrl: './contract-management-module.component.html',
  styleUrls: ['./contract-management-module.component.css']
})
export class ContractManagementModuleComponent implements OnInit {
  maxSize:number=1000;
  srcfile:any[]=[];
  displayPdf:boolean=false;
  pdfContent:any;
  fileObject:any;
  files:any[]=[];
  displayDialog:boolean=false;
  isNoDocument:boolean=false;
  uploadedFiles: any[] = [];
  typeOfContactDD:any[] =[];
  selectedtypeofContract:any ='';
  constructor(private service:FopHttpService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.selectedtypeofContract='';
    this.getContractDetails();
  }

  getContractDetails(){
      this.service.getContractTypes().subscribe((response: any) => {
        if (!response['isError']) {
          console.log(response.response)
          this.typeOfContactDD = response.response;
        }
      });
    
  }
  selectedContractChange(){
    this.getContractDocument(this.selectedtypeofContract);
  }
  getContractDocument(typeofContract:any){
    this.spinner.show()
    this.service.getContractDocumentMaster(typeofContract).subscribe((response:any)=>{
      this.files=response.response;
      if(!this.files.length)
      {
        this.isNoDocument=true;
      }  
      else{
        this.isNoDocument=false;
        console.log(this.files,"this is the get object from db");
        this.fileObject=this.files.find(file=>!file.isOriginal);
        const source=`data:${this.fileObject.mimeType.toLowerCase()};base64,${this.fileObject.fileContentString}`;
        this.pdfContent=source;
        this.displayPdf=true;
      }      
      this.spinner.hide()
    })
  }

  displayFile(event:any){
    console.log(event.currentFiles,"checking on select")   
  }  

  async uploadHandler(event:any){ 
    this.spinner.show();     
    if (event.files.length > 0) {          
      let contractdocs= await this.getFileObj(event.files[0]);
      this.uploadedFiles.push(contractdocs);    
           
      this.service.updateContractDocumentMaster(contractdocs).subscribe((response:any)=> {
        this.service.displayToastr(response);
        this.displayPdf=false;
        this.closeDialog();
        this.uploadedFiles=[];
        this.getContractDocument(this.selectedtypeofContract);
        this.spinner.hide();
      });
    }
  }
  getFileObj = (f: any): Promise<any> => {
    let fileObj = {
      fileContentString: '',      
      fileName: '',
      MIMEType: '',
      ContractId:0      
    };
    const file = f;
    let fileType = f.type;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((res, rej) => {
      reader.onload = () => {
        let fileData: any = reader.result;
        this.srcfile=fileData;
        console.log(fileData,"this is the upload object from FE")
        fileData = fileData.replace(`data:${fileType};base64,`, '');        
        fileObj.fileContentString = fileData;
        fileObj.fileName = file.name;       
        fileObj.MIMEType = fileType;   
        fileObj.ContractId=this.selectedtypeofContract     
        res(fileObj);
      }
    })

  }


  onSubmit(signatorydetails:any){   
      this.spinner.show();
      this.displayPdf=false;
      let payload={      
        adminName:signatorydetails.adminName,
        adminTitle:signatorydetails.adminTitle,
        adminSignatureImage:signatorydetails.adminSignatureImage,
        pdfFileObject:this.files.find(file=>file.isOriginal),
        ContractId:this.selectedtypeofContract
      }
      
      this.service.updateDocwithSignatorydetails(payload).subscribe(response=>{
        console.log(response);
        this.service.displayToastr(response);
        this.getContractDocument(this.selectedtypeofContract);
        this.spinner.hide();
      })   
  }

  openDialog(){
    this.uploadedFiles=[];
    console.log(this.uploadedFiles,"checking files on open")
    this.displayDialog=true;
  }

  closeDialog(){
    this.uploadedFiles = [];
    this.displayDialog = false;    
  }



}

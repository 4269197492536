// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
    display: block;
    border: 1px solid 
  rgb(105, 105, 105);
    background-color: var(--ion-color-success);
    width: 60%;
  }
  button {
    margin: 10px;
    margin-left: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/app/components/shared/esign/esign.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd;oBACgB;IAChB,0CAA0C;IAC1C,UAAU;EACZ;EACA;IACE,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":["canvas {\r\n    display: block;\r\n    border: 1px solid \r\n  rgb(105, 105, 105);\r\n    background-color: var(--ion-color-success);\r\n    width: 60%;\r\n  }\r\n  button {\r\n    margin: 10px;\r\n    margin-left: 10px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

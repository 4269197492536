// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custContractDoc {    
    padding: 15px;
    background-color: #8f8f8f;
    display: flex;
    margin-left: 0.1%;
    width: 99.9%;
}

.pdfsection{
    
    border: 2px solid #dee2e6;
    height: 700px;
  }
  .signatorysection{
    margin-top:20px;
    border: 2px solid #dee2e6;
  }

  .retailersection{
    margin-top: 25px;
    
  }

  .cust-textbox-1{
    height:30px;
    width:18.4rem;
    text-transform:uppercase;
  }
  .valmsg{
    margin-left:12rem;
  }

  .cust-field{
    margin-bottom: 0.7rem;
  }`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/customer-contract-document/customer-contract-document.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,iBAAiB;IACjB,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;IACzB,aAAa;EACf;EACA;IACE,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;;EAElB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,wBAAwB;EAC1B;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":["#custContractDoc {    \r\n    padding: 15px;\r\n    background-color: #8f8f8f;\r\n    display: flex;\r\n    margin-left: 0.1%;\r\n    width: 99.9%;\r\n}\r\n\r\n.pdfsection{\r\n    \r\n    border: 2px solid #dee2e6;\r\n    height: 700px;\r\n  }\r\n  .signatorysection{\r\n    margin-top:20px;\r\n    border: 2px solid #dee2e6;\r\n  }\r\n\r\n  .retailersection{\r\n    margin-top: 25px;\r\n    \r\n  }\r\n\r\n  .cust-textbox-1{\r\n    height:30px;\r\n    width:18.4rem;\r\n    text-transform:uppercase;\r\n  }\r\n  .valmsg{\r\n    margin-left:12rem;\r\n  }\r\n\r\n  .cust-field{\r\n    margin-bottom: 0.7rem;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css'],
})
export class CommentsComponent implements OnInit {
  fopId = parseInt(sessionStorage.getItem('fopId') + '');
  userId = parseInt(sessionStorage.getItem('userId') + '');
  parentId: any;

  newParentComment: string = '';
  reqBody: any;
  apiResp: any;
  @Input() comments: any;
  @Input() hideNewComment: boolean = false;
  showReplies: boolean = false;
  showReplyDiv: boolean = true;
  deleteReplyDisp: boolean = false;
  replies: any;
  replyQuesId: any;
  selQuesReplyId = '';
  selReply = '';
  showReply: boolean = false;
  quesReplyText = '';
  replyFunctionality = 'Show';
  pageNumber = 0;
  rows = 10;
  loadReplyId: any;
  searchText = '';
  sResultsDiv: boolean = false;
  resultsCount = 0;
  contributor: boolean = true;
  dispLoginDialog: boolean = false;
  SUCCESS = "Success";
  REPLY_SENT = "Reply Sent"

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private service: FopHttpService,
    private translate: TranslateService
  ) { 
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  ngOnInit() {
    if (!this.comments) {
      this.getComments();
    }
    this.initializeTabItems();
  }

  initializeTabItems() {
    this.translate.instant(this.SUCCESS);
    this.translate.instant(this.REPLY_SENT);
  }

  getComments() {
    if (!this.parentId) this.parentId = 0;

    this.spinner.show();
    this.service
      .GetFopComments(this.fopId, this.parentId, this.userId)
      .subscribe((response: any) => {
        this.apiResp = response;
        this.comments = this.formatDate(this.apiResp.response);
        this.resultsCount = this.comments.length;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      });
  }

  formatDate(commentsArray: any) {
    commentsArray.forEach((comment: any) => {
      comment.formattedDate = new Date(comment.date)
        .toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(/ /g, '-');
    });
    return commentsArray;
  }

  displayReply(data: any) {
    if (this.contributor) {
      this.showReply = true;
      this.selQuesReplyId = data.comment_Id;
    }
  }

  cancelReply() {
    this.showReply = false;
    this.selQuesReplyId = '';
  }

  viewAnswers(commentId: any) {
    this.service
      .GetFopComments(this.fopId, commentId, this.userId)
      .subscribe((response: any) => {
        this.apiResp = response;
        this.replies = this.formatDate(this.apiResp.response);
        this.replyQuesId = this.replies[0].parentId;
        this.showReplies = true;
        this.replyFunctionality = 'Hide';
      });
  }

  hideReplies() {
    this.showReplies = false;
    this.replyQuesId = '';
    this.replyFunctionality = 'Show';
  }

  saveComment() {
    this.spinner.show();
    let requestObj = {
      fop_Id: this.fopId,
      comment_Id: 0,
      comments: this.newParentComment,
      parentId: 0,
      user: this.userId.toString(),
      date: new Date(),
      repliesCount: 0,
    };
    this.service.CreateComments(requestObj).subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (response.isError) {
        this.service.displayToastr(response);
        return;
      }
      this.getComments();
      this.newParentComment = '';
    });
  }

  submitAnswer(parentComment: any) {
    this.reqBody = {
      fop_Id: this.fopId,
      comment_Id: 0,
      comments: this.quesReplyText,
      parentId: parentComment.comment_Id,
      user: this.userId.toString(),
      date: new Date(),
      repliesCount: 0,
    };
    this.spinner.show();
    this.service.CreateComments(this.reqBody).subscribe((response: any) => {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if (response.isError) {
        this.service.displayToastr(response);
        return;
      }
      this.cancelReply();
      this.ToastMsg('Success', this.REPLY_SENT, this.SUCCESS);
      this.quesReplyText = '';
      this.viewAnswers(parentComment.comment_Id);
    });
  }

  ToastMsg(
    ToastType: string,
    FirstMessage: string | undefined,
    UserMessage: string | undefined
  ) {
    if (ToastType == 'Error') {
      this.toastr.error(FirstMessage, UserMessage, {
        closeButton: true,
      });
    } else if (ToastType == 'Warning') {
      this.toastr.warning(FirstMessage, UserMessage, {
        closeButton: true,
      });
    } else if (ToastType == 'Success') {
      this.toastr.success(FirstMessage, UserMessage, {
        closeButton: true,
      });
    }
  }
}

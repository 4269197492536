import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShippingRequirementsHelper {
  shippingData = new BehaviorSubject({} as ShippingPayload);

  sendShippingData(value: ShippingPayload) {
    this.shippingData.next(value);
  }
  getShippingData(): Observable<any> {
    return this.shippingData.asObservable();
  }
}

export interface ShippingPayload {
  bulkTruckRequests: any;
  packageTruckRequests: any;
  railRequests: any;
}

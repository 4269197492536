// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-label {
    font-size: 16px;
    font-weight: 500;
}
.add-btn {
    color: white;
    background-color: #0096d6;
    margin:10px;
    border-radius: 5px;
    float: right;
}

#user-module .p-dropdown {
    width: 100%;
    border: 1px solid black;
}

#user-module .p-multiselect {
    width: 100%;
    border: 1px solid black;
}

#user-module p-sorticon.p-element {
    padding-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/admin/user-module/user-module.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".header-label {\r\n    font-size: 16px;\r\n    font-weight: 500;\r\n}\r\n.add-btn {\r\n    color: white;\r\n    background-color: #0096d6;\r\n    margin:10px;\r\n    border-radius: 5px;\r\n    float: right;\r\n}\r\n\r\n#user-module .p-dropdown {\r\n    width: 100%;\r\n    border: 1px solid black;\r\n}\r\n\r\n#user-module .p-multiselect {\r\n    width: 100%;\r\n    border: 1px solid black;\r\n}\r\n\r\n#user-module p-sorticon.p-element {\r\n    padding-right: 0px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cust-form-label {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    display: block;
}

.cust-form-group {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.form-group {
    display: flex;
    margin-top: 5px;
}



.line {
    border-right: 1px solid gray;
    margin: 0%;
}

input:read-only {
    background-color: rgb(202, 200, 200);
}
#new-customer-setup .p-inputtext, #new-customer-setup .p-dropdown {
    width: 100% !important;
}
.p-tooltip-text {
    font-size: 12px;
}

#address_details_mandatory_section .p-accordion-header-link,
#ship_to_address_mandatory_section .p-accordion-header-link,
#paperwork_requirements_mandatory_section .p-accordion-header-link,
#tax_status_mandatory_section .p-accordion-header-link {
  color: red;
  background: #cfcfcf;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/new-customer/new-customer-setup/new-customer-setup.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;;;AAIA;IACI,4BAA4B;IAC5B,UAAU;AACd;;AAEA;IACI,oCAAoC;AACxC;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,eAAe;AACnB;;AAEA;;;;EAIE,UAAU;EACV,mBAAmB;AACrB","sourcesContent":[".cust-form-label {\r\n    font-size: 14px !important;\r\n    margin-bottom: 0px !important;\r\n    display: block;\r\n}\r\n\r\n.cust-form-group {\r\n    justify-content: flex-end;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.form-group {\r\n    display: flex;\r\n    margin-top: 5px;\r\n}\r\n\r\n\r\n\r\n.line {\r\n    border-right: 1px solid gray;\r\n    margin: 0%;\r\n}\r\n\r\ninput:read-only {\r\n    background-color: rgb(202, 200, 200);\r\n}\r\n#new-customer-setup .p-inputtext, #new-customer-setup .p-dropdown {\r\n    width: 100% !important;\r\n}\r\n.p-tooltip-text {\r\n    font-size: 12px;\r\n}\r\n\r\n#address_details_mandatory_section .p-accordion-header-link,\r\n#ship_to_address_mandatory_section .p-accordion-header-link,\r\n#paperwork_requirements_mandatory_section .p-accordion-header-link,\r\n#tax_status_mandatory_section .p-accordion-header-link {\r\n  color: red;\r\n  background: #cfcfcf;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';
import {
  ShippingPayload,
  ShippingRequirementsHelper,
} from '../shipping.requirements.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bulk-truck-request',
  templateUrl: './bulk-truck-request.component.html',
  styleUrls: ['./bulk-truck-request.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class BulkTruckRequestComponent implements OnInit {
  InvalidEmailsListcofA = '';
  InvalidEmailsListcontactASN = '';
  @Input() submitFopTriggered!: Subject<string>;
  @Input() savedFormData: any;
  @Input() disableForm: any;
  @Output() closeFormEvent = new EventEmitter();
  @Output() submitFormEvent = new EventEmitter();
  @Output() getExistingShippingData = new EventEmitter();
  @Output() deleteForm = new EventEmitter();
  @Output() exportBlkFormData = new EventEmitter();
  enduserFop: any = sessionStorage.getItem('enduserFop');
  fopType: string | null = '';
  incoterm: any = sessionStorage.getItem('incoterm');
  coa: any = sessionStorage.getItem('coa');
  asn: any = sessionStorage.getItem('asn');
  coaEmail: any = sessionStorage.getItem('coaEmail');
  asnEmail: any = sessionStorage.getItem('asnEmail');
  submitted: boolean = false;
  bulkRequestDetails: UntypedFormGroup = {} as UntypedFormGroup;
  masterData: any;
  liquidOrDryBulkDD: any = [];
  preppingRequirementsDD: any = [];
  washingRequirementsDD: any = [];
  plantHolidayShdlDD: any = [];
  yesOrNo = ['NA', 'Yes', 'No'];
  fopReadyToGO = sessionStorage.getItem('fopReadyToGO');
  shippingData: ShippingPayload = {} as ShippingPayload;
  @Input() companyCityStateOfShippingAddress: any[] = [];
  fopId = sessionStorage.getItem('fopId');
  @Input() materialNumberList: any = [];
  endUserBusiness: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private _sharedParameter: SharedParameterService,
    private _fopHttpService: FopHttpService,
    private _shippingHelper: ShippingRequirementsHelper,
    private translate: TranslateService
  ) {
    this.coa = this.yesOrNo[parseInt(this.coa ? this.coa : '0')];
    this.asn = this.yesOrNo[parseInt(this.asn ? this.asn : '0')];

    this.bulkRequestDetails = this.formBuilder.group({
      isReqFiledsFilled: [false],
      shippingID: 0,
      foP_Id: parseInt(sessionStorage.getItem('fopId') + ''),
      userId: [sessionStorage.getItem('userId')],

      equipment: this.formBuilder.group({
        dischargeMethod: [''],
        pressure: [''],
        prepRequirements: [''],
        washingRequirements: [''],
        other1: [''],
        other2: [''],
      }),
      receivingContact: this.formBuilder.group(
        {
          multiCompanyCityState: this.formBuilder.group({
            companyName: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
          }),
          companyname: ['', Validators.required],
          destinationcity: ['', Validators.required],
          destinationstate: ['', Validators.required],
          name: ['', Validators.required],
          phoneNo: [
            '',
            [
              Validators.required,
              Validators.pattern('[- +()0-9]+'),
              Validators.minLength(10),
              Validators.maxLength(17),
            ],
          ],

          contactASN: ['', Validators.required],
          customerProductCode: [''],
          normalOrderQty: [''],
          liquidDryBulk: [''],
          shipMaxNumberSlots: null,
          email: ['', [Validators.required, Validators.email]],
          fax: [''],
          receivingHrsFrom: ['', Validators.required],
          receivingHrsTo: ['', Validators.required],
          plantHolidayShdl: ['', Validators.required],
          contactCOA: ['', Validators.required],
          materialDetails: this.formBuilder.array([
            this.formBuilder.group({
              materialNumber: [''],
              materialName: [''],
            }),
          ]),
          estimatedAnnualQuantity: ['', Validators.required],
        },
        { validators: receivingContactValidator }
      ),
      pumpRequirements: this.formBuilder.group({
        furnishedByCarrier: ['no'],
        pumpSpecification: [''],
      }),
      discharge: this.formBuilder.group({
        centerUnloaderPrefAcceptRear: 0,
        centerUnloaderReq: 0,
        rearUnloaderPrefAcceptCenter: 0,
        rearUnloaderReq: 0,
        kamlockOPWQuickConnect: [''],
        civiconKamvlockDryBreak: [''],
        diameter: [''],
      }),
      hoseRequirements: this.formBuilder.group({
        fourtyHoseStandardLength: [''],
        additionalHoseCharged: [''],
      }),
      vapour: this.formBuilder.group({
        topCenterTrailer: [''],
        topFrontTrailer: [''],
        topRareTrailer: [''],
        groundCenterTrailer: [''],
        groundFrontTrailer: [''],
        groundRareTrailer: [''],
        openDemoVenting: [''],
        openCleanVenting: [''],
        chicagoPneumaticCrowsFoot: [''],
        kamlokQuickConnect: [''],
        vapourDiameter1: [''],
        civaconKamvalokDryBreak: [''],
        vapourDiameter2: [''],
        vapourHoseSizeDiameter: [''],
        vapourAllChemicalStd: [''],
        vapourstainlessSteel: [''],
      }),
      deliveryTemperature: this.formBuilder.group(
        {
          tempControlRequired: [0, Validators.required],
          minFahrenheitCelsius: 0,
          maxFahrenheitCelsius: 0,
        },
        { validators: tempReqValidator }
      ),
      driverRequirements: this.formBuilder.group({
        chemicalSuit: 0,
        hardHatSaftyGlass: 0,
        closeToeFtware: 0,
        cleanShaven: 0,
        rubberGloves: 0,
        monogoggles: 0,
        steelToeBoots: 0,
        rubberBoots: 0,
        twicCard: 0,
        otherSiteEntranceReq1: [''],
        otherSiteEntranceReq2: [''],
      }),
      deliveryApt: this.formBuilder.group(
        {
          deliveryAppointReq: [0, Validators.required],
          contactAptName: [''],
          contactAptPhone: [
            '',
            [
              Validators.pattern('[- +()0-9]+'),
              Validators.minLength(10),
              Validators.maxLength(17),
            ],
          ],
          contactAptEmail: ['', Validators.email],
          farAdvance: [''],
          delayedContact: [''],
        },
        { validators: deliveryAptValidator }
      ),
      preferredCarrier: this.formBuilder.group(
        {
          carrierName: [''],
          carrierPhoneContact: [
            '',
            [
              Validators.pattern('[- +()0-9]+'),
              Validators.minLength(10),
              Validators.maxLength(17),
            ],
          ],
          carrierEmailContact: ['', Validators.email],
        },
        { validators: preferredCarrierValidator }
      ),
      other: [''],
    });

    this.fopType = localStorage.getItem('basicFopTypeId');

    this.masterData = this._fopHttpService.getSavedMasterData();
    if (this.masterData) {
      for (const dataObj of this.masterData) {
        this.setMasterDataObjects(dataObj);
      }
    }
    this.translate.stream('ALL_FOP').subscribe((resp) => {
      this.initializeTabItems();
    });
  }

  ngOnInit(): void {
    console.log(sessionStorage?.['enduserFop'])
    this.initializeTabItems();
    if (this.enduserFop!= null || this.enduserFop != undefined) {
      console.log(this.enduserFop)
      this.endUserBusiness =JSON.parse(this.enduserFop).businessUnit;
      if(JSON.parse(this.enduserFop).businessUnit !='AP')

      this.bulkRequestDetails.get('normalOrderQty')?.setValidators(Validators.required);
    }
    if (this.fopType != '4') {
      this.bulkRequestDetails.controls['receivingContact']
        .get('companyname')
        ?.clearValidators();
      this.bulkRequestDetails.controls['receivingContact']
        .get('destinationcity')
        ?.clearValidators();
      this.bulkRequestDetails.controls['receivingContact']
        .get('destinationstate')
        ?.clearValidators();
    } else {
      this.bulkRequestDetails.controls['receivingContact']
        .get('multiCompanyCityState')
        ?.get('companyName')
        ?.clearValidators();
      this.bulkRequestDetails.controls['receivingContact']
        .get('multiCompanyCityState')
        ?.get('city')
        ?.clearValidators();
      this.bulkRequestDetails.controls['receivingContact']
        .get('multiCompanyCityState')
        ?.get('state')
        ?.clearValidators();
    }

    this._shippingHelper
      .getShippingData()
      .subscribe((data: ShippingPayload) => {
        this.shippingData = data;
      });      

    if (this.savedFormData) {
      this.bindValuesForSavedFormData();
    }
    if (this.coa == 'Yes') {
      this.bulkRequestDetails.patchValue({
        receivingContact: {
          contactCOA: this.coaEmail,
        },
      });
    } else {
      this.bulkRequestDetails.controls['receivingContact']
        .get('contactCOA')
        ?.clearValidators();
    }
    if (this.asn == 'Yes') {
      this.bulkRequestDetails.patchValue({
        receivingContact: {
          contactASN: this.asnEmail,
        },
      });
    } else {
      this.bulkRequestDetails.controls['receivingContact']
        .get('contactASN')
        ?.clearValidators();
    }
    if (this.disableForm || this.fopReadyToGO === 'yes') {
      this.bulkRequestDetails.disable();
    }

    this.submitFopTriggered.subscribe((value) => {
      this.saveFormData();
    });
  }

  initializeTabItems() {
    this.yesOrNo = [
      'NA',
      this.translate.instant('YES'),
      this.translate.instant('NO'),
    ];
  }

  bindValuesForSavedFormData(){
    this.bulkRequestDetails.patchValue(this.savedFormData);
      this.submitted = true;
      if (this.savedFormData.receivingContact) {
        console.log('blk');
        if(this.savedFormData.receivingContact.contactASN){
          let savedAsnEmail=this.savedFormData.receivingContact.contactASN;
          this.asnEmail=(savedAsnEmail!=undefined && savedAsnEmail!='')?savedAsnEmail:this.asnEmail;
        }
        if(this.savedFormData.receivingContact.contactCOA){
          let savedCOAEmail=this.savedFormData.receivingContact.contactCOA;
          this.coaEmail=(savedCOAEmail!=undefined && savedCOAEmail!='')?savedCOAEmail:this.coaEmail;
        }
        this.BindCompanyCityStateToForm();
      }

      this.InitializeMaterialFormArray();
  }

  BindCompanyCityStateToForm() {
    let dd = this.savedFormData.receivingContact.multiCompanyCityState as any[];
    console.log(dd,"checking dd")
    let frm = this.bulkRequestDetails.get('receivingContact');
    console.log(frm,"checking frm");
    if (dd && dd.length > 0) {
      let ids = dd.map((k) => k.addressId);
      frm?.patchValue({
        multiCompanyCityState: {
          companyName: ids,
          city: ids,
          state: ids,
        },
      });
    }
  }

  get f() {
    return this.bulkRequestDetails.controls as any;
  }
  getValueOfSelectedMaterial(index: number) {
    if (index >= 0) {
      let temparray: any = this.bulkRequestDetails
        .get('receivingContact')
        ?.get('materialDetails') as UntypedFormArray;
      let matNum = temparray?.value[index].materialNumber;

      if (matNum) {
        let val: any = this.materialNumberList.find(
          (k: any) => k.materialNumber == matNum
        );
        if (val?.materialName) {
          return val.materialName;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  onSelectCompany(event: any) {
    let id = event.value;
    console.log(id);
    console.log(this.bulkRequestDetails.value);

    let cmp = this.bulkRequestDetails
      .get('receivingContact')
      ?.get('multiCompanyCityState');

    cmp?.get('city')?.patchValue(id);
    cmp?.get('state')?.patchValue(id);
  }
  setMasterDataObjects(element: any) {
    if (element['masterKey'] == 'LIQUID OR DRY BULK')
      this.liquidOrDryBulkDD = element['masterkeyDataList'];
    if (element['masterKey'] == 'PREPPING REQUIREMENTS')
      this.preppingRequirementsDD = element['masterkeyDataList'];
    if (element['masterKey'] == 'WASHING REQUIREMENTS')
      this.washingRequirementsDD = element['masterkeyDataList'];
    if (element['masterKey'] == 'PUBLIC HOLIDAYS')
      this.plantHolidayShdlDD = element['masterkeyDataList'];
  }

  get GetMaterialFormArray() {
    return this.bulkRequestDetails
      .get('receivingContact')
      ?.get('materialDetails') as UntypedFormArray;
  }

  MapCompanyCityState() {
    let cmp =
      this.bulkRequestDetails.value.receivingContact.multiCompanyCityState;
    let ret: any[] = [];
    let tempId = cmp?.companyName as any[];
    if (tempId && tempId.length > 0) {
      tempId.forEach((k: string) => {
        let tempm = this.companyCityStateOfShippingAddress.find(
          (p) => p.addressId == k
        );

        if (tempm) {
          ret.push({
            addressId: tempm.addressId,
            companyName: tempm.companyName,
            city: tempm.city,
            state: tempm.state,
          });
        }
      });
    }

    return ret;
  }

  deleteItem() {
    let shippingID: number = this.savedFormData.shippingID;
    if (shippingID == 0) {
      this.deleteForm.emit();
    } else {
      this._fopHttpService
        .deleteShiipingData(this.fopId, shippingID)
        .subscribe((response: any) => {
          let IsError: boolean = response.IsError;
          if (!IsError) {
            this.deleteForm.emit();
          }
        });
    }
  }
  saveFormData() {
    if (!this.bulkRequestDetails.value.foP_Id) {
      return;
    }
    if (this.fopReadyToGO === 'yes') {
      return;
    }
    if (this.enduserFop!= null || this.enduserFop != undefined) {
      console.log(this.enduserFop)
      if(JSON.parse(this.enduserFop).businessUnit =='AP')
      this.endUserBusiness =JSON.parse(this.enduserFop).businessUnit;
      this.bulkRequestDetails.get('normalOrderQty')?.clearValidators();
    }
    this.submitted = true;
    this.bulkRequestDetails.value.isReqFiledsFilled =
      !this.bulkRequestDetails.invalid;
    this.bulkRequestDetails.value.receivingContact.multiCompanyCityState =
      this.MapCompanyCityState();

    const findExisting = this.shippingData.bulkTruckRequests?.find(
      (o: any) =>
        o.shippingID == this.bulkRequestDetails.value.shippingID &&
        this.bulkRequestDetails.value.shippingID != 0
    );
    let newCustomerObj: any;
    if (findExisting) {
      newCustomerObj = this.bulkRequestDetails.value;
      newCustomerObj.materialDetails =
        this.mapMaterialListiWithFormGroup(newCustomerObj);

      newCustomerObj.isReqFiledsFilled = !this.bulkRequestDetails.invalid;

      this.shippingData.bulkTruckRequests[
        this.shippingData.bulkTruckRequests.indexOf(findExisting)
      ] = newCustomerObj;
    } else {
      newCustomerObj = this.bulkRequestDetails.value;
      newCustomerObj.materialDetails =
        this.mapMaterialListiWithFormGroup(newCustomerObj);

      newCustomerObj.isReqFiledsFilled = !this.bulkRequestDetails.invalid;
      if (this.shippingData.bulkTruckRequests) {
        this.shippingData.bulkTruckRequests.push(newCustomerObj);
      } else {
        this.shippingData.bulkTruckRequests = [newCustomerObj];
      }
      this.bulkRequestDetails.reset();
    }
    this.exportBlkFormData.emit(newCustomerObj);
    this._shippingHelper.sendShippingData(this.shippingData);
  }
  closeForm() {
    sessionStorage.removeItem('enduserFop');
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    this.closeFormEvent.emit();
  }

  addMaterial(): void {
    const matForm = this.formBuilder.group({
      materialNumber: [''],
      materialName: [''],
    });
    this.GetMaterialFormArray.push(matForm);
  }
  removeMaterial(index: number): void {
    this.GetMaterialFormArray.removeAt(index);
    let item = this.savedFormData.materialDetails as any[];
    item.splice(index, 1);
    this.savedFormData.materialDetails = item;
  }
  InitializeMaterialFormArray() {
    let form = this.bulkRequestDetails
      .get('receivingContact')
      ?.get('materialDetails') as UntypedFormArray;

    if (this.savedFormData) {
      let matdet = this.savedFormData.materialDetails as any[];
      if (matdet != undefined && matdet.length > 0) {
        form.clear();
        for (const iterator of matdet) {
          let ctrl = this.formBuilder.group({
            materialNumber: [iterator.materialNumber],
            materialName: [iterator.materialName],
          });
          form.push(ctrl);
        }
      }
    }
  }
  
  mapMaterialListiWithFormGroup(newCustomerObj: any): any {
    let val = newCustomerObj.receivingContact.materialDetails as any[];
    let newval: any[] = [];

    if (this.savedFormData) {
      let matdet = this.savedFormData.materialDetails as any[];

      for (let i = 0; i < val.length; i++) {
        let mat =
          matdet != undefined && matdet.length > 0 ? matdet[i] : undefined;
        if (mat) {
          newval.push({
            id: mat.id,
            materialNumber: val[i].materialNumber,
            materialName: val[i].materialName,
          });
        } else
          newval.push({
            id: 0,
            materialNumber: val[i].materialNumber,
            materialName: val[i].materialName,
          });
      }
    }
    return newval;
  }

  validateEmailMultipleID(getPaperworkField: any) {
    if (
      getPaperworkField ==
      this.bulkRequestDetails.value.receivingContact.contactCOA
    ) {
      let emailsSeparator =
        this.bulkRequestDetails.value.receivingContact.contactCOA.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);
      this.InvalidEmailsListcofA = '' + invalidEmails;
    }
    if (
      getPaperworkField ==
      this.bulkRequestDetails.value.receivingContact.contactASN
    ) {
      let emailsSeparator =
        this.bulkRequestDetails.value.receivingContact.contactASN.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListcontactASN = '' + invalidEmails;
    }
  }
  returnEmail(emailsSeparator: any, invalidEmails: any) {
    for (const iterator of emailsSeparator) {
      if (!this.validateEmailmultiple(iterator.trim())) {
        invalidEmails.push(iterator.trim());
      }
    }
  }
  validateEmailmultiple(emailsSeparator: any) {
    let re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return re.test(emailsSeparator);
  }
  coaAsnChange(value: any, type: string) {
    sessionStorage.setItem(type, value);
  }
}

export const deliveryAptValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const deliveryAppointReq = control.get('deliveryAppointReq');
  const contactAptName = control.get('contactAptName');
  const contactAptPhone = control.get('contactAptPhone');
  const contactAptEmail = control.get('contactAptEmail');
  const farAdvance = control.get('farAdvance');
  const delayedContact = control.get('delayedContact');

  let errors = {
    contactAptName: contactAptName?.invalid,
    contactAptName_control: contactAptName,
    contactAptPhone: contactAptPhone?.invalid,
    contactAptPhone_control: contactAptPhone,
    contactAptEmail: contactAptEmail?.invalid,
    contactAptEmail_control: contactAptEmail,
    farAdvance: farAdvance?.invalid,
    farAdvance_control: farAdvance,
    delayedContact: delayedContact?.invalid,
    delayedContact_control: delayedContact,
  };

  return deliveryAppointReq?.value &&
    (contactAptName?.invalid ||
      contactAptPhone?.invalid ||
      contactAptEmail?.invalid ||
      farAdvance?.invalid ||
      delayedContact?.invalid)
    ? errors
    : null;
};

export const receivingContactValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const name = control.get('name');
  const phoneNo = control.get('phoneNo');
  const email = control.get('email');
  const coa = control.get('contactCOA');
  const asn = control.get('contactASN');
  const receivingHrsFrom = control.get('receivingHrsFrom');
  const receivingHrsTo = control.get('receivingHrsTo');
  const plantHolidayShdl = control.get('plantHolidayShdl');

  let errors = {
    name: name?.invalid,
    name_control: name,
    phoneNo: phoneNo?.invalid,
    phoneNo_control: phoneNo,
    email: email?.invalid,
    email_control: email,
    coa: coa?.invalid,
    coa_control: coa,
    asn: asn?.invalid,
    asn_control: asn,
    receivingHrsFrom: receivingHrsFrom?.invalid,
    receivingHrsFrom_control: receivingHrsFrom,
    receivingHrsTo: receivingHrsTo?.invalid,
    receivingHrsTo_control: receivingHrsTo,
    plantHolidayShdl: plantHolidayShdl?.invalid,
    plantHolidayShdl_control: plantHolidayShdl,
  };

  return name?.invalid ||
    phoneNo?.invalid ||
    email?.invalid ||
    coa?.invalid ||
    asn?.invalid ||
    receivingHrsFrom?.invalid ||
    receivingHrsTo?.invalid ||
    plantHolidayShdl?.invalid
    ? errors
    : null;
};

export const tempReqValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const tempControlRequired = control.get('tempControlRequired');
  const minFahrenheitCelsius = control.get('minFahrenheitCelsius');
  const maxFahrenheitCelsius = control.get('maxFahrenheitCelsius');

  return tempControlRequired?.value &&
    (minFahrenheitCelsius?.value === 0 || maxFahrenheitCelsius?.value === 0)
    ? {
        minFahrenheitCelsiusRequired: minFahrenheitCelsius?.value === 0,
        minFahrenheitCelsius_control: minFahrenheitCelsius,
        maxFahrenheitCelsiusRequired: maxFahrenheitCelsius?.value === 0,
        maxFahrenheitCelsius_control: maxFahrenheitCelsius,
      }
    : null;
};

export const preferredCarrierValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const carrierPhoneContact = control.get('carrierPhoneContact');
  const carrierEmailContact = control.get('carrierEmailContact');

  const errors = {
    carrierPhoneContact_control: carrierPhoneContact,
    carrierEmailContact_control: carrierEmailContact,
  };

  return carrierPhoneContact?.invalid || carrierEmailContact?.invalid
    ? errors
    : null;
};

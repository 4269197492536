import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-tax-exempt-certificate',
  templateUrl: './tax-exempt-certificate.component.html',
  styleUrls: ['./tax-exempt-certificate.component.css'],
})
export class TaxExemptCertificateComponent implements OnInit {
  @Output() closeFormEvent = new EventEmitter();
  salesTaxExemptionForm: UntypedFormGroup;
  fopId: any = parseInt(sessionStorage.getItem('fopId') + '');
  submitted: boolean = false;
  disableForm: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private _fopHttpService: FopHttpService,
    private toastr: ToastrService
  ) {
    this.salesTaxExemptionForm = this.fb.group({
      fopId: [this.fopId],
      taxExemptCertificate: ['', Validators.required],
      taxExemptCertificateName: [''],
      createdBy: ['0'],
    });
  }

  ngOnInit(): void {
    //  fetch saved tax doc
    this.spinner.show();
    this._fopHttpService
      .getTaxExemptionCertificate(this.fopId)
      .subscribe((response: any) => {
        if (response.taxExemptCertificate) {
          this.salesTaxExemptionForm.patchValue(response);
        }
      });
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }

  get f() {
    return this.salesTaxExemptionForm.controls;
  }

  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }

  onSubmit() {
    if (this.salesTaxExemptionForm.invalid) {
      this._fopHttpService.displayToastr({
        isError: true,
        errorMessage: 'Tax Exempt certificate is mandatory.',
      });
      return;
    }
    this.spinner.show();
    this._fopHttpService
      .saveTaxExceptCertificate(this.salesTaxExemptionForm.value)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
        if (!response.isError) {
          console.log(response);
        }
        this._fopHttpService.displayToastr(response);
      });
  }

  getTemplate() {
    this._fopHttpService
      .getTaxExemptionTemplateCertificate()
      .subscribe((response: any) => {
        const data = response.taxCertificateDocument;
        const filename = response.taxCertificateName;
        this.download(data, filename);
      });
  }

  download(data: any, fileName: any) {
    if (!fileName) {
      return;
    }
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement('a');
    const filename = fileName;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }

  onCertificateChange(event: any) {
    if (event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        let fileType = event.target.files[0].type;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let fileData: any = reader.result;
          fileData = fileData.replace(`data:${fileType};base64,`, '');
          this.salesTaxExemptionForm.patchValue({
            taxExemptCertificate: fileData,
            taxExemptCertificateName: event.target.files[0].name,
          });
        };
      } catch (err) {
        console.log(err);
      }
    }
  }
}

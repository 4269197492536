import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FopHttpService } from 'src/app/services/fop-http.service';

@Component({
  selector: 'app-sbu-module',
  templateUrl: './sbu-module.component.html',
  styleUrls: ['./sbu-module.component.css'],
})
export class SbuModuleComponent implements OnInit {
  cols: any;
  divisionData: any;
  dropDownData: any[] = [];
  divisionAPIResp: any;
  filteredFields: any;
  filteredData: any;
  tempFilteredData: any;
  selectedRow: any;
  formType: any;
  showForm: boolean = false;
  showGrid = false;
  divisionName: any = '';
  sbuName: any;
  sbuId = 0;
  modifiedby: any;
  apiRequestObj: any = {
    sbuId: 0,
    divisionId: 0,
    sbuName: '',
    createdBy: '',
  };

  constructor(
    private service: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.getDivisionDropDownData();
    this.getAllDivisionData();
  }
  cancel() {
    this.clear();
    this.divisionName = '';
    this.showForm = false;
    this.getAllDivisionData();
  }
  clear() {
    this.sbuId = 0;
    this.divisionName = '';
    this.sbuName = '';
    this.showForm = false;
  }

  submit() {
    this.spinner.show();
    this.apiRequestObj['divisionId'] = this.divisionName;
    this.apiRequestObj['sbuName'] = this.sbuName;
    this.apiRequestObj['sbuId'] = this.sbuId;
    this.apiRequestObj['createdBy'] = sessionStorage.getItem('userId') + '';
    this.service
      .postCreateOrUpdateSbu(this.apiRequestObj)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.divisionData = JSON.parse(JSON.stringify(this.apiRequestObj));
          this.service.displayToastr(this.divisionData);
          this.clear();
          this.getAllDivisionData();
        } else {
          this.service.displayToastr(response);
        }
      });
    this.spinner.hide();
  }

  addNewSbuData() {
    this.showForm = true;
  }

  onEdit(rowObject: any) {
    this.showForm = true;
    this.sbuId = rowObject.sbuId;
    this.divisionName = rowObject.divisionId;
    this.sbuName = rowObject.sbuName;
  }

  onDelete(rowObject: any) {
    this.sbuId = rowObject.sbuId;
    this.modifiedby = rowObject.createdBy;
    this.service
      .deleteSBU(this.sbuId, this.modifiedby)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.apiRequestObj = response;
          this.service.displayToastr(this.apiRequestObj);
          this.getAllDivisionData();
        } else {
          this.service.displayToastr(response);
        }
      });
  }

  getDivisionDropDownData() {
    this.spinner.show();
    this.service.getAllDivisions().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.divisionAPIResp = response.response;
        this.divisionAPIResp.forEach((key: any) => {
          this.dropDownData.push({
            key: key.divisionName,
            value: key.divisionId,
          });
        });
      } else {
        this.service.displayToastr(response);
      }
    });
    this.spinner.hide();
  }

  getAllDivisionData() {
    this.spinner.show();
    this.service.getAllSbuData().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.divisionAPIResp = response.response;
        this.divisionData = JSON.parse(JSON.stringify(this.divisionAPIResp));
      } else {
        this.service.displayToastr(response);
      }
    });
    this.spinner.hide();
  }
}
